<template>
    <lince-map-wrapper
        ref="linceMapWrapper"
        :driver="driver"
        :drivers="['google']"
        :renderDomElementId="renderDomElementId"
        :apiKeys="mapApiKeys"
        :markerIconHost="markerIconHost"
        :mapParams="mapParams"
        :clusteringParams="clusteringParams"
        :clusteringServerParams="clusteringServerParams"
        :searchItemsApproxed="searchItemsApproxed"
        :isControlPanel="true"
        :isLegendPanel="true"
        :legends="legends"
        :legendConfig="legendConfig"
        :isLoading="isLoadingLegend"
        :panelItemsVisible="panelItemsVisible"
        :mapModeSelected="modeIdSelected"
        @onToggleDriver="onToggleDriver"
        @onClickMarker="onClickMarker"
        @onAfterCreatePolygon="onAfterCreatePolygon"
        @onAfterSelectSideInBox="onAfterSelectSideInBox"
        @onInputSearch="onInputSearch"
        @onSelectItemSearch="onSelectItemSearch"
        @onInitMap="onInitMap"
        @onReadyMap="onReadyMap"
        @onToggleSelectMode="onToggleSelectMode"
        @onToggleMassSelectMode="onToggleMassSelectMode"
        @onToggleDirectionAngleView="onToggleDirectionAngleView"
        @onToggleDirectionFlowSide="onToggleDirectionFlowSide"
        @onSelectLegendGroup="onSelectLegendGroup"
        @onToggleLegendItem="onToggleLegendItem"
        @onSelectLegendColor="onSelectLegendColor"
        @onSelectLegendFigure="onSelectLegendFigure"
        @onMapClick="onMapClick"
        @onBoundsChanged="onBoundsChanged"
    >
        <!-- <control-panel-v2
            :panelIdsActive="controlPanelIdsActive"
            :panelBlocksUnvisible="isLegendPanel ? [] : ['mode']"
            :panelItemsUnvisible="driver === 'yandex' ? ['pedestrianTraffic', 'autoTraffic'] : []"
            @onTogglePedestrianTraffic="onTogglePedestrianTraffic"
            @onToggleAutoTraffic="onToggleAutoTraffic"
            @onToggleJamsTraffic="onToggleJamsTraffic"
            @onToggleColorMap="onToggleColorMap"
            @onToggleRuler="onToggleRuler"
            @onToggleSelectMode="onToggleSelectMode"
            @onToggleMassSelectMode="onToggleMassSelectMode"
            @onToggleClustering="onToggleClustering"
            @onToggleDirectionAngleView="onToggleDirectionAngleView"
            @onToggleDirectionFlowSide="onToggleDirectionFlowSide"
            class="lince-map-wrapper__control-panel"
        /> -->
        <!-- <legend-panel
            v-if="isLegendPanel"
            :markers-statuses="markersStatuses"
            class="lince-map-wrapper__legend-panel"
            @activeLegendPanel="activeLegendPanel"
        /> -->
    </lince-map-wrapper>
</template>

<script>
// import LegendPanel from '@/components/MapV2/components/LegendPanel/LegendPanel.vue';
// import ControlPanelV2 from '@/components/MapV2/components//ControlPanelV2/ControlPanel.vue';
import ServiceBuyerdeskMap from '../../services/ServicesBuyerdesk/ServiceBuyerdeskMap';
import ServiceToken from '../../services/ServicesAuth/ServiceToken';
import SchemeMap from '../../schemes/SchemeMap';
const {panelItemsVisible} = SchemeMap;
export default {
    name: 'LinceMapView',
    components: {
        // LegendPanel,
        // ControlPanelV2
    },
    props: {
        markersStatuses: {
            type: Array,
            default: () => ([]),
        },
        isLegendPanel: {
            type: Boolean,
            default: true
        },
        legendConfig: {
            type: Object,
            default: () => ({isFigureSelector:true})
        },
        isLoadingLegend: {
            type: Boolean,
            default: false
        },
        legendData: {
            type: Object,
            default: () => ({})
        },
        legendGroups: {
            type: Array,
            default: () => ([])
        },
        legendItems: {
            type: Array,
            default: () => ([
                // {
                //     active: true,
                //     color: "#55dd43",
                //     count: 2042,
                //     figure: "circle",
                //     id: "found--default--27614",
                //     title: "Найдено",
                // },
                // {
                //     active: true,
                //     color: "#008000",
                //     count: 35,
                //     figure: "circle",
                //     id: "found--status--booked",
                //     title: "Забронировано"
                // },
                // {
                //     active: true,
                //     color: "#ffff00",
                //     count: 1276,
                //     figure: "circle",
                //     id: "found--status--reserved2",
                //     title: "Резерв 1"
                // },
                // {
                //     active: true,
                //     color: "#55dd43",
                //     count: 2042,
                //     figure: "circle",
                //     id: "custom--default--27614",
                //     title: "Набор 1",
                // },
                // {
                //     active: true,
                //     color: "#008000",
                //     count: 2042,
                //     figure: "circle",
                //     id: "custom--default--27615",
                //     title: "Набор 2",
                // },
                // {
                //     active: true,
                //     color: "#008000",
                //     count: 35,
                //     figure: "circle",
                //     id: "custom--status--booked",
                //     title: "Забронировано"
                // },
                // {
                //     active: true,
                //     color: "#ffff00",
                //     count: 1276,
                //     figure: "circle",
                //     id: "custom--status--reserved2",
                //     title: "Резерв 1"
                // }
            ])
        },
    },
    data: () => ({
        driver: 'google',
        renderDomElementId: 'map-wrapper',
        mapApiKeys: {
            google: process.env.VUE_APP_MAP_API_GOOGLE,
            yandex: process.env.VUE_APP_MAP_API_YANDEX
        },
        mapParams: {
            center: {lat: 55.7558, lng: 37.6178},
            zoom: 10,
        },
        clusteringParams: {
            actionFilterMarkers: (marker) => {
                const {extraData = {}, isRender = false} = marker;
                const {kitId = "0", type = 'default'} = extraData;
                return (String(kitId) === "0" || String(kitId) === '-2') && type !== 'poi' && isRender;
            }
        },
        markerIconHost: process.env.VUE_APP_FREE_BACKEND_HOST,
        clusteringServerHost: process.env.VUE_APP_FREE_BACKEND_HOST,
        clusteringServerRoute: '/map/cluster-get',
        apiAuthToken: ServiceToken.productToken,
        modeOptions: [
            {
                id: 'selection',
                value: 'Выбор точки'
            },
            {
                id: 'massSelection',
                value: 'Массовый выбор точек'
            }
        ],
        modeIdSelected: 'selection',
        controlPanelIdsActive: [],
        searchItemsApproxed: [],
        panelItemsVisibleScheme: {...panelItemsVisible},
    }),
    computed: {
        clusteringServerParams() {
            return {
                apiAuthToken: this.apiAuthToken,
                clusteringServerHost: this.clusteringServerHost,
                clusteringServerRoute: this.clusteringServerRoute
            };
        },
        panelItemsVisible() {
            return this.panelItemsVisibleScheme[this.driver] ?? [];
        },
        legends() {
            const legends = Object.keys(this.legendData).reduce((legends, legendDataKey) => {
                const legendItems = this.legendItems.reduce((legendItems, legendItem) => {
                    const {id = ''} = legendItem;
                    const idArr = String(id).split('--');
                    const legendKey = String(idArr[0] ?? '');
                    const legendItemGroup = String(idArr[1] ?? '');
                    if (legendKey === legendDataKey) {
                        let legendItemsGroups = legendItems[legendItemGroup] ?? [];
                        legendItemsGroups.push(legendItem);
                        legendItems[legendItemGroup] = [...legendItemsGroups];
                    }

                    return legendItems;
                }, {});

                let legendGroups;
                switch (legendDataKey){
                    case "found":
                        legendGroups = this.legendGroups.filter(legendGroup => String(legendGroup?.id) !== 'default');
                        break;
                    case "without_prices":
                        legendGroups = this.legendGroups.filter(legendGroup => !['date', 'status'].includes(String(legendGroup?.id)));
                        break;
                    default:
                        legendGroups = [...this.legendGroups];
                }

                legends[legendDataKey] = {
                    ...this.legendData[legendDataKey],
                    groups: [...legendGroups],
                    items: {...legendItems}
                };

                return legends;
            }, {});

            return legends;
        }
    },
    beforeMount() {
        if (localStorage.getItem('mapDriver'))
            this.driver = localStorage.getItem('mapDriver');
    },
    methods: {
        /** Карта инициализируется */
        onInitMap() {
            this.$emit('onInitMap');
        },
        /** Карта готова */
        onReadyMap() {
            setTimeout(() => {
                this.$emit('onReadyMap');
                this.$refs.linceMapWrapper.onToggleClustering(false, () => true);
            }, 500);
        },
        /**
         * Переключить движок карты
         * @param {String} driver - движок карты
         */
        onToggleDriver(driver = '') {
            // this.driver = String(driver);
            localStorage.setItem('mapDriver', driver);
            window.location.reload();
        },
        /**
         * Событие поиска на карте
         * @param {String} searchValue - значение поля поиска
         */
        onInputSearch(searchValue = '') {
            this.searchMap(String(searchValue));
        },
        /**
         * Событие выбора элемента поиска
         * @param {Object} item - элемент из поиска
         */
        onSelectItemSearch(item = {}) {
            const {value = ''} = item;
            this.searchMap(String(value));
        },
        /**
         * Событие поиска на карте
         * @param {String} searchValue - значение поля поиска
         */
        searchMap(searchValue = '') {
            if (this.getIsSearchValue(searchValue)) {
                ServiceBuyerdeskMap.search(searchValue, (searchItems) => {
                    this.searchItemsApproxed = searchItems;
                }, () => {
                    // alert('Не удалось осуществить поиск по адресу');

                    this.$notify.dNotify({
                        type: 'error',
                        title: 'Не удалось осуществить поиск по адресу',
                        description: 'Пожалуйста, уточните запрос',
                        duration: Infinity,
                        position: 'bottom-right'
                    });
                });
            }
            else {
                this.searchItemsApproxed = [];
            }
        },
        /**
         * Получить условие непустой строки
         * @param {String} searchValue - строка поиска
         * @returns {Boolean}
         */
        getIsSearchValue(searchValue = '') {
            // eslint-disable-next-line no-useless-escape
            return String(searchValue).replace(/[\s.,\/#!$%\^&\*;:{}=\-_`~()?]/g, '') !== '';
        },
        /**
         * Событие переключения пешеходного траффика
         * @param {Boolean} active - активность плашки пешеходного траффика
         */
        onTogglePedestrianTraffic(active = false) {
            this.$refs.linceMapWrapper.onTogglePedestrianTraffic(active);
        },
        focusMap(lat, lng, zoom){
            this.$refs.linceMapWrapper.scaleMap([{lat, lng}], zoom);
        },
        /** Получение границ экрана */
        getBounds() {
            return this.$refs.linceMapWrapper.getBounds();
        },
        /** Получение зума */
        getZoom() {
            return this.$refs.linceMapWrapper.getZoom();
        },
        /**
         * Событие переключения автомобильного траффика
         * @param {Boolean} active - активность плашки автомобильного траффика
         */
        onToggleAutoTraffic(active = false) {
           this.$refs.linceMapWrapper.onToggleAutoTraffic(active);
        },
        /**
         * Событие переключения пробок
         * @param {Boolean} active - активность плашки пробок
         */
        onToggleJamsTraffic(active = false) {
            this.$refs.linceMapWrapper.onToggleJamsTraffic(active);
        },
        /**
         * Событие переключения цвета карты
         * @param {Boolean} active - активность плашки цвета карты
         */
        onToggleColorMap(active = false) {
            this.$refs.linceMapWrapper.onToggleColorMap(active);
        },
        /**
         * Событие переключения активности линейки
         * @param {Boolean} active - активность плашки линейки карты
         */
        onToggleRuler(active = false) {
            this.$refs.linceMapWrapper.onToggleRuler(active);
        },
        /**
         * Переключить режим выбора
         */
        onToggleSelectMode() {
            if (this.modeIdSelected !== 'selection')
                this.onSelectMode('selection');
        },
        /**
         * Переключить массовый режим выбора
         */
        onToggleMassSelectMode() {
            if (this.modeIdSelected !== 'massSelection')
                this.onSelectMode('massSelection');
        },
        /**
         * Включить/отключить кластеризацию на карте
         * @param {Boolean} active - активность плашки кластеризации
         */
        onToggleClustering(active = false) {
            this.$refs.linceMapWrapper.onToggleClustering(active, (marker) => {
                const {extraData = {}, isRender = true} = marker;
                const {kitId = null} = extraData;
                return (String(kitId) === "0" || String(kitId) === '-2') && isRender;
            });
        },
        onToggleDirectionAngleView(active = false) {
            this.$refs.linceMapWrapper.onToggleDirectionAngleView(active, this.actionFilterMarkersToggleDirection);
            this.$emit("onChangeIconType", active ? 'directionAngleView' : 'default');
        },
        onToggleDirectionFlowSide(active = false) {
            this.$refs.linceMapWrapper.onToggleDirectionFlowSide(active, this.actionFilterMarkersToggleDirection);
            this.$emit("onChangeIconType", active ? 'directionFlowSide' : 'default');
        },
        actionFilterMarkersToggleDirection(marker) {
            const {extraData = {}} = marker;
            const {type = 'default'} = extraData;
            return type !== 'poi';
        },

        /**
         * Событие выбора группы в легенде
         * @param {String} legendKey - ключ легенды
         * @param {String} groupId - id группы
         */
        onSelectLegendGroup(legendKey = '', groupId = '') {
            this.$emit('onSelectLegendGroup', legendKey, groupId);
        },

        /**
         *
         */
        onSelectLegendColor(legendData, color){
            this.$emit('onSelectLegendColor', legendData, color);
        },

        /**
         *
         */
        onSelectLegendFigure(legendData, figure){
            this.$emit('onSelectLegendFigure', legendData, figure);
        },

        /**
         * Событие переключения элемента легенды
         * @param {Object} legendData - данные легенды
         * @param {String} legendData.legendKey - ключ легенды
         * @param {String} legendData.group - ключ группы
         * @param {String} legendData.legendItemId - id элемента легенды
         * @param {Boolean} legendData.active - активность элемента легенды
         */
        onToggleLegendItem(legendData = {}) {
            this.$emit('onToggleLegendItem', legendData);
        },

        /**
         * Событие удаления инициализируемой линейки
         */
        destroyInitRuler() {
            this.controlPanelIdsActive.splice(this.controlPanelIdsActive.findIndex(panelId => panelId === 'ruler'), 1);
            this.$refs.linceMapWrapper.destroyInitRuler();
        },
        /**
         * Событие выбора режима
         * @param {String} id - идентификатор режима
         */
        onSelectMode(id = '') {
            this.modeIdSelected = String(id);
            if (this.modeIdSelected === 'selection')
                this.$refs.linceMapWrapper.destroyInitPolygon();
            else
                this.$refs.linceMapWrapper.initPolygon();
        },
        onClickMarker(marker = {}, typeEvent = '') {
            const {type = null} = marker;
            if(type === 'cluster'){
                this.$emit('onClickCluster', marker);
                return;
            }
            switch (typeEvent) {
                case 'left':
                    if (this.modeIdSelected === 'selection')
                        this.$emit('onSelectMarker', marker);
                    break;
                case 'right':
                    if (marker.extraData.type !== 'poi') {
                        this.$refs.linceMapWrapper.setMarkerClicked(marker);
                        this.$emit('onSelectMarkerRight', marker);
                    }
                    break;
                default:
                    break;
            }
        },
        /**
         * Событие выбора маркера левой кнопкой мыши
         * @param {Object} marker - маркер
         */
        onSelectMarker(marker = {}) {
            this.$emit('onSelectMarker', marker);
        },
        /**
         * Событие выбора маркера правой кнопкой мыши
         * @param {Object} marker - маркер
         */
        onSelectMarkerRight(marker = {}) {
            this.$emit('onSelectMarkerRight', marker);
        },
        /**
         * Событие сработающее после создания полигона
         * @param {Object} polygon - полигон
         */
        onAfterCreatePolygon(polygon = {}) {
            this.$refs.linceMapWrapper.initPolygon();
            const markersContainsLocation = this.$refs.linceMapWrapper.containsLocation(polygon);
            const clustersContainsLocation = this.$refs.linceMapWrapper.containsLocationClusters(polygon);
            this.$emit('onMassSelectMarkers', markersContainsLocation, clustersContainsLocation);
        },
        onAfterSelectSideInBox(data){
            this.$emit('onAfterSelectSideInBox', data);
        },
        unsetMarkerClicked() {
            this.$refs.linceMapWrapper.unsetMarkerClicked();
        },
        /**
         * Перерисовать маркеры
         * @param {Function} actionFilter - событие для фильтрации маркеров на карте
         * @param {Array} markers - маркеры
         * @param {Boolean} isRender - условие отрисовки
         */
        redrawMarkers(actionFilter = () => {}, markers = [], isRender = true, isUpdateClustering = true) {
            this.$refs.linceMapWrapper.redrawMarkers(actionFilter, markers, isRender, isUpdateClustering);
        },
        /**
         * Перерисовать кластеры
         * @param {Function} actionFilter - событие для фильтрации маркеров на карте
         * @param {Array} clusters - маркеры
         */
        redrawClusters(actionFilter = () => {}, clusters = []) {
            this.$refs.linceMapWrapper.redrawExternalClusters(actionFilter, clusters);
        },
        /**
         * Обновить маркеры
         * @param {Function} actionUpdate - функция обновления
         */
        updateMarkers(actionUpdate = () => {}, isUpdateClustering = true) {
            this.$refs.linceMapWrapper.updateMarkers(actionUpdate, isUpdateClustering);
        },
        /**
         * Включить панораму
         * @param {String} lat - широта
         * @param {String} lng - долгота
         */
        setStreetView(lat = '', lng = '') {
            this.$refs.linceMapWrapper.setStreetView(lat, lng);
        },
        /**
         * Масштабировать карту
         */
        fitBounds() {
            this.$refs.linceMapWrapper.fitBounds((marker) => {
                const {extraData = {}} = marker;
                const {type = 'default'} = extraData;
                return type !== 'poi';
            });
        },
        /**
         * Передать родителю список активных статусов LegendPanel
         */
        activeLegendPanel() {
            this.$emit('activeLegendPanel');
        },
        initSideSelectionBox(center = {}, sides = []) {
            this.$refs.linceMapWrapper.initSideSelectionBox(center, sides);
        },
        /**
         * Событие инициализации информационного окна
         * @param {Object} center - координаты точки
         * @param {Object} poiInfo - координаты точки
         */
        initWindowInfoPoi(center = {}, poiInfo = {}) {
            this.$refs.linceMapWrapper.initWindowInfoPoi(center, poiInfo);
        },
        onMapClick(coords) {
            this.$emit('onMapClick', coords)
        },
        onBoundsChanged({bounds,zoom}){
            this.$emit('onBoundsChanged', bounds, zoom);
        }
    }
}
</script>

<style lang="scss" scoped>
    .lince-map-wrapper {
        &__legend-panel {
            position: absolute;
            top: 10px;
            right: 110px;
        }

        &__control-panel {
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 2;
        }
    }
</style>
