<template>
    <div class="page-bayuerdesk">
        <transition name="page-bayuerdesk__loading">
            <div v-if="isLoadingFilter" class="page-bayuerdesk__loading">
                <base-preloader-gif :size="40"/>
            </div>
        </transition>
        <!--TODO: Удалить как будут готовы уведомления-->
        <!-- <BaseButton @click="createNotification" view="secondary" style="width: fit-content; margin: 20px auto;">Creat notify</BaseButton> -->
        <kit-panel
            :kits="kitPanelItems"
            :kitCustomIdSelected="kitCustomIdSelected"
            :kitsTableActive="kitsFolderIdsActive"
            :kitsMarkersActive="kitsMarkersActive"
            :isMarginLeft="true"
            :kitCanAdd="!isShare"
            :isShare="isShare"
            :isLoadingKitFound="isLoadingKitFound"
            :isLoadingKitsCustom="isLoadingKitsCustom"
            @onSelectKit="onSelectKit"
            @onCreateKit="onCreateKit"
            @onMatchFromFile="onMatchFromFile"
            @onRenameKit="onRenameKit"
            @onRemoveKit="onRemoveFolder"
            @onToggleTableKit="onToggleTableKit"
            @onToggleMarkersKit="onToggleMarkersKit"
            @onDuplicate="onDuplicate"
        />
        <div class="page-bayuerdesk-map">
            <ProgressBar
                :isLoading="isLoadingMap"
                class="page-bayuerdesk-map__progressbar"
            />
            <div class="page-bayuerdesk-map__wrapper">
                <!-- <map-view
                    ref="mapView"
                    :markers-statuses="markersStatuses"
                    :isLegendPanel="!isShare"
                    @onSelectMarker="onSelectMarker"
                    @onSelectMarkerRight="onSelectMarkerRight"
                    @onMassSelectMarkers="onMassSelectMarkers"
                    @activeLegendPanel="onChangeMarkers"
                    @onAfterSelectSideInBox="onAfterSelectSideInBox"
                /> -->
                <lince-map-view
                    ref="mapView"
                    :markers-statuses="markersStatuses"
                    :isLegendPanel="!isShare"
                    :isLoadingLegend="isLoadingLegend"
                    :legendData="!isShare ? legendData : {}"
                    :legendGroups="legendGroups"
                    :legendItems="legendItems"
                    @onSelectMarker="onSelectMarker"
                    @onSelectMarkerRight="onSelectMarkerRight"
                    @onMassSelectMarkers="onMassSelectMarkers"
                    @activeLegendPanel="onChangeMarkers"
                    @onAfterSelectSideInBox="onAfterSelectSideInBox"
                    @onSelectLegendGroup="onSelectLegendGroup"
                    @onToggleLegendItem="onToggleLegendItem"
                    @onSelectLegendColor="onSelectLegendColor"
                    @onSelectLegendFigure="onSelectLegendFigure"
                    @onMapClick="onMapClick"
                    @onBoundsChanged="onBoundsChanged"
                    @onClickCluster="onClickCluster"
                    @onReadyMap="onReadyMap"
                    @onChangeIconType="onChangeIconType"
                />

                <!-- <layout-modules
                    v-if="!isYandexMap"
                    @onToggleGroupPoi="toggleGroupPoi"
                    @onToggleOnePoi="toggleOnePoi"
                    @onTogglePoiAll="togglePoiAll"
                    @onChangeMarkerStylePoi="changeMarkerStylePoi"
                    @onUpdateMarkerPoi="updateMarkerPoi"
                /> -->

                <layout-modules
                    ref="layoutModules"
                    :moiPoiCoords="moiPoiCoords"
                    @onDrawPoiMarkers="onDrawPoiMarkers"
                    @onFilterPoiRadius="onFilterPoiRadius"
                    @onClearPoiMarkers="onClearPoiMarkers"
                    @onTogglePoiMarker="onTogglePoiMarker"
                    @onUpdateColorMarkers="onUpdateColorMarkers"
                    @onUpdateIconMarkers="onUpdateIconMarkers"
                    @onDrawCompetitorMarkers="onDrawCompetitorMarkers"
                    @onClearCompetitorMarkers="onClearCompetitorMarkers"
                    @onChangeCompetitorColor="onChangeCompetitorColor"
                />
                <card-free
                    v-if="isCardVisible"
                    :sides="cardData"
                    :kitPrices="cardKitPrices"
                    :selectedData="cardMarkerSelected"
                    :filterSelectedDates="filterSelectedDates"
                    class="page-bayuerdesk-map__construction-card"
                    @close="onCloseCard"
                    @onCheckPrice="onCheckPrice"
                    @onPanoramaGoogle="onPanoramaGoogle"
                />
            </div>
        </div>
        <folder
            v-if="!isShare"
            :id="kitFound.id"
            :title="kitFound.title"
            :isMinimize="false"
            :width="calculateFolderWidth('found', folderSize)"
            :height="calculateFolderHeight('found', folderSize)"
            :top="getPositionFolderKit(kitFound.id, 'top', folderSize)"
            :left="getPositionFolderKit(kitFound.id, 'left', folderSize)"
            :color="getColorKit(kitFound.id)"
            :isRename="false"
            @click.native="onSelectKit(kitFound.id, 'found')"
        >
            <template v-slot:summary>
                <AppFooter class="folder__legend"/>
            </template>
            <template v-slot:panel>
                <FolderControlPanel
                    class="folder__panel"
                    :isFound="true"
                    :isDuplicate="false"
                    :isRemove="false"
                    :isExport="tableRows.length > 0"
                    @exportExcel="onExportFolder(kitFound.id)"
                    @addAll="onCheckAddAll"
                    @onFullSize="toggleResizeFolder({id: kitFound.id, resize: 'full'})"
                    @onFiftySize="toggleResizeFolder({id: kitFound.id, resize: 'fifty'})"
                    @onCloseFolder="closeFolder(kitFound.id)"
                />
            </template>
            <template v-slot:content>
                <kit-tab
                    :canCheck="true"
                    :columns="tableColumns"
                    :modifieredCols="tableColumnsModifered"
                    :datesCols="tableColumnsDates"
                    :tableData="tableRows"
                    :folderId="kitFound.id"
                    :rowsSelected="tableFoundRowsSelected"
                    :rowsSelectedPart="tableFoundRowsSelectedPart"
                    :rowsCheckboxes="tableCustomPricesSelected"
                    :style="{'height': '100%'}"
                    :isProgressBarLoading="isProgressBarLoading"
                    @lazyload="onLazyloadTableDataFound"
                    @filter="onFilterTableDataFound"
                    @sort="onSortTableDataFound"
                    @onCheckSubCheckbox="onCheckSubCheckboxFound"
                    @onCheckRow="onCheckRowFound"
                    @onCheckRowsAll="onCheckRowsAllFound"
                    @onClickCell="onClickCell"
                    @onAddPricesRow="onCheckSubCheckboxFound($event, 'prices', 'add')"
                />
            </template>
        </folder>
        <layout-folder
            v-for="(kit) in kitCustoms"
            ref="layoutFolder"
            :key="kit.id"
            :id="kit.id"
            :title="kit.title"
            :folderType="kit.type"
            :width="calculateFolderWidth('kit', folderSize)"
            :height="calculateFolderHeight('kit', folderSize)"
            :left="getPositionFolderKit(kit.id, 'left', folderSize)"
            :top="getPositionFolderKit(kit.id, 'top', folderSize)"
            :color="getColorKit(kit.id)"
            :isRename="true"
            :isCurrent="String(kitCustomIdSelected) === String(kit.id)"
            :isRemove="kitCustoms.length > 1"
            :isDuplicate="!isShare"
            :currentIconType="currentIconType"
            @onRenameFolder="onRenameKit"
            @onClickFolder="onSelectKit"
            @onCheckSubCheckbox="onCheckSubCheckboxFound($event, 'allPrices')"
            @beforeGetMarkers="beforeGetMarkers"
            @afterGetMarkers="afterGetMarkersCustom"
            @drawMarkers="drawMarkersFound"
            @removeFolder="onRemoveFolder"
            @exportFolder="onExportFolder"
            @moveToBudget="onMoveToBudget"
            @onDuplicate="onDuplicate"
            @onClickCell="onClickCell"
            @onRemovePriceTable="onCheckSubCheckboxFound($event, 'allPrices', 'remove')"
            @updateKitItem="updateKit"
        >
        </layout-folder>
        <ExportTemplate
            v-if="isExporting"
            :userTemplates="userTemplates"
            :currentTemplate="currentTemplate"
            @onGetTemplates="onGetTemplates"
            @onCloseExportFolder="onCloseExportFolder"
            @onSaveTemplate="onSaveTemplate"
            @onCheckTemplate="onCheckTemplate"
            @onExportTemplate="onExportTemplate"
            @onRemoveTemplate="onRemoveTemplate"
        />
        <!-- <ContainerFileMatching
            v-if="isMatchingFromFile"
            @onCloseMatching="onCloseMatching"
            @onCompleteMatching="onCompleteMatching"
        /> -->
        <layout-match
            v-if="isMatchingFromFile"
            @onClose="onCloseMatching"
            @onComplete="onCompleteMatching"
        />

        <RealtimeSystemMap/>
    </div>
</template>

<script>
import {mapGetters, mapState, mapMutations} from 'vuex';
import BasePreloaderGif from "@/components/Base/BasePreloaderGif.vue";
import MapView from '@/components/MapV2/MapView';
import LinceMapView from '@/components/MapV3/LinceMapView';
import KitPanel from '@/components/KitPanelV2/KitPanel';
import LayoutFolder from '@/layouts/LayoutsPageBayuerdesk/LayoutFolder';
import LayoutMatch from '@/layouts/LayoutsPageBayuerdesk/LayoutMatch';
import Folder from '@/components/Folder/Folder';
import MixinApi from '@/mixins/MixinsPageBayuerdesk/MixinApi';
import MixinKitFolder from '@/mixins/MixinsPageBayuerdesk/MixinKitFolder';
import MixinKitPanel from '@/mixins/MixinsPageBayuerdesk/MixinKitPanel';
import MixinTableFound from '@/mixins/MixinsPageBayuerdesk/MixinTableFound';
import MixinMapFound from '@/mixins/MixinsPageBayuerdesk/MixinMapFound';
import KitTab from '@/components/HandbookComponents/KitTab';
import CardFree from '@/components/ConstructionCardControllers/CardFree'
import FolderControlPanel from "@/components/Folder/FolderControlPanel";
import ExportTemplate from "@/components/Export/ExportTemplate";
import MixinExport from "@/mixins/MixinExport";
import MixinMatchingFromFile from "@/mixins/MixinMatchingFromFile";
// import ContainerFileMatching from "@/components/Match/ContainerFileMatching";
import ProgressBar from '@/components/Base/ProgressBar';
import LayoutModules from '@/components/MapV2/components/LayoutModules/LayoutModules';
import ServiceMapLegend from '../services/ServicesMap/ServiceMapLegend'
// import UtilPointDistance from "../utils/UtilPointDistance";
// import lodash from "lodash";
import AppFooter from '../components/ConstructionCard/ConstructionCardFree/Components/Footer.vue';
import MixinActionMapCache from "@/mixins/MixinActionMapCache";
import RealtimeSystemMap from "@/components/MapV2/RealtimeSystem/RealtimeSystemMap";

export default {
    name: 'PageBayuerdeskV2',
    components: {
        RealtimeSystemMap,
        // ContainerFileMatching,
        BasePreloaderGif,
        // eslint-disable-next-line vue/no-unused-components
        MapView,
        LinceMapView,
        KitPanel,
        LayoutFolder,
        LayoutMatch,
        Folder,
        KitTab,
        CardFree,
        FolderControlPanel,
        ExportTemplate,
        ProgressBar,
        LayoutModules,
        AppFooter
    },
    mixins: [
        MixinApi,
        MixinKitFolder,
        MixinKitPanel,
        MixinTableFound,
        MixinMapFound,
        MixinExport,
        MixinMatchingFromFile,
        MixinActionMapCache
    ],
    /**
     * Входные данные компонента
     * @property {Boolean} isShare - условие шары
     */
    props: {
        isShare: {
            type: Boolean,
            default: false
        }
    },
    /**
     * Данные компонента
     * @property {String} isProgressBarLoading - Показать/скрыть preloader страницы при загрузке
     * @property {String} isLoadingFilter - статус инициализации фильтра
     * @property {Array} markersStatuses - список статусов маркеров
     * @property {Object} activeLegendPanelMarkersStatuses - список выбранных статусов маркеров в базовой легенде
     * @property {String} currentSelectionSideMode - режим выбора конструкции с карты
     */
    data: () => ({
        isProgressBarLoading: false,
        isLoadingFilter: false,
        isLoadingKitFound: false,
        isLoadingKitsCustom: false,
        isLoadingMap: false,
        markersStatuses: [],
        mapDriver: 'google',
        isWithoutPrices: false,
        moiPoiCoords: [],
        legendScheme: {
            found: {
                title: "Легенда найденого",
                groupSelected: "status",
            },
            custom: {
                title: "Легенда наборов",
                groupSelected: "default",
            },
            without_prices: {
                title: "Легенда без цен",
                groupSelected: "default",
            }
        },
        legendData: {},
        legendGroups: [],
        legendItems: [],
        isLoadingLegend: false,
        legendTypesMap: {
            found: 0,
            custom: 1,
            without_prices: 2
        },
        legendKeysMap: {
            0: 'found',
            1: 'custom',
            2: 'without_prices'
        },
        isUpdateLegend: true,
        currentCityId: null,
        currentViewZoom: null,
        loadedBound: null,
        isFirstStart: true,
        poiRadius: 0,
        poiPointsDetailed: {},
        currentIconType: 'default'
    }),
    computed: {
        /** Проксируем состояния из стора */
        ...mapState({
            filterSelectedData: state => state.filter.filterSelectedData,
            tableCustomPricesSelected: state => state.kit.tableCustomPricesSelected,
            tableCustomRows: state => state.kit.tableCustomRows,
            withoutPricesStepCount: state => state.filter.withoutPricesStepCount,
            withoutPricesSideCount: state => state.filter.withoutPricesSideCount,
            isFilterLoading: state => state.filter.isFilterLoading,
            activeLegendPanelMarkersStatuses: state => state.pageBayuerdesk.activeLegendPanelMarkersStatuses,
            allActiveLegendPanelMarkersStatuses: state => state.pageBayuerdesk.allActiveLegendPanelMarkersStatuses,
            markersStatusesListDefault: state => state.pageBayuerdesk.markersStatusesListDefault,
        }),
        ...mapGetters('pageBayuerdesk', ['onlyWithoutPrice']),
        ...mapGetters('filter', ['currentCity']),
        ...mapGetters('widgetPoi', [
            'dataPoints',
            'checkedParentList',
            'checkedChildList',
        ]),
        /** Проксируем геттеры из стора */
        // ...mapGetters('kit', ['tableFoundRowsSelected']),
        filterSelectedDates() {
            return this.filterSelectedData?.date ?? [];
        },
        /**
         * Выбранные строки текущего набора
         * @returns {Array}
         */
        tableFoundRowsSelected() {
            let tableFoundRowsSelected = [];
            Object.keys(this.tableCustomPricesSelected).forEach(rowId => {
                const datesPrices = this.tableCustomPricesSelected[rowId] || [];
                const {date: datesFilter = []} = this.filterSelectedData;
                const datesPricesFiltered = datesPrices.filter(date => datesFilter.includes(date));
                if (datesPricesFiltered.length !== 0)
                    tableFoundRowsSelected.push(rowId);
            });
            return tableFoundRowsSelected;
        },
        /**
         * Частично выделенные строки
         * @returns {Array}
         */
        tableFoundRowsSelectedPart() {
            const tableFoundRowsSelectedPart = this.tableFoundRowsSelected.filter(id => {
                let isFilter = false;
                const row = this.tableRows.find(row => String(row.id) === String(id));
                if (typeof row !== 'undefined') {
                    const {prices = []} = row;
                    const isEvery = prices.every(price => this.tableCustomPricesSelected[id].includes(price.date));
                    isFilter = !isEvery;
                }
                return isFilter;
            });
            return tableFoundRowsSelectedPart;
        },
        /**
         * Выделеные маркеры найдено
         * @returns {Array}
         */
        mapFoundMarkersSelectedIds() {
            const tableCustomRowsFiltered = this.tableCustomRows.filter(row => {
                const {id = -1} = row;
                return this.tableFoundRowsSelected.includes(String(id));
            });
            const mapFoundMarkersSelectedIds = tableCustomRowsFiltered.map(row => {
                const {marker_id = '-1'} = row;
                return marker_id;
            });
            return mapFoundMarkersSelectedIds;
        },
        mapWithoutPriceSelectedCatalogIds() {
            let index = this.kitCustoms.findIndex(kit => String(kit.id) === this.kitCustomIdSelected);
            return this.$refs.layoutFolder[index].tableRowsCatalogIdsSelected || [];
        },
        isYandexMap() {
            return this.mapDriver === 'yandex';
        },
        kitItemsMarkersActive() {
            return this.kitPanelItems.filter(kitItem => this.kitsMarkersActive.includes(String(kitItem?.id ?? '')));
        },
        kitTypesMarkersActive() {
            return this.kitItemsMarkersActive.reduce((kitItemsTypes, kitItem) => {
                const {type = ''} = kitItem;
                if (!kitItemsTypes.includes(String(type)))
                    kitItemsTypes.push(String(type));

                return kitItemsTypes;
            }, []);
        },
        legendDataTypes() {
            return Object.keys(this.legendData);
        },
        kitIdsCustoms() {
            return this.kitCustoms.map(kit => String(kit?.id ?? ''));
        },
        kitsCustomMarkersActive() {
            return this.kitsMarkersActive.filter(kitId => this.kitIdsCustoms.includes(String(kitId)));
        },

        poiPoints() {
            return Object.values(this.poiPointsDetailed).map((point) => {
                const {lat = 0, lng = 0} = point;
                return {lat, lng};
            })
        }
    },
    watch: {
        /** Следим за изменением фильтра и подтягиваем найдено */
        filterSelectedData() {
            this.toggleTableAndMap();
        },

        /** Следим за изменениями выделенных строк найдено и обновляем маркеры */
        // eslint-disable-next-line no-unused-vars
        tableFoundRowsSelected(tableFoundRowsSelectedNew, tableFoundRowsSelectedOld) {
            this.setMarkersSelectFound(this.kitFoundId);
            this.setMarkersSelectWithoutPrice();

            // const index = this.kitCustoms.findIndex(kit => String(kit.id) === String(this.kitCustomIdSelected));

            // if (!lodash.isEqual(tableFoundRowsSelectedNew, tableFoundRowsSelectedOld)) {
            //     // eslint-disable-next-line no-debugger
            //     debugger;
            //     this.$refs.layoutFolder[index].updateMarkers();
            //     this.$refs.layoutFolder[index].postSummaryData();
            // }
        },

        kitsMarkersActive() {
            if (this.isUpdateLegend)
                this.updateLegend();
        }
    },
    beforeMount() {
        // if (!this.isShare)
        //     this.isLoadingFilter = true;
        if (localStorage.getItem('mapDriver'))
            this.mapDriver = localStorage.getItem('mapDriver');
    },
    mounted() {
        this.isLoadingKitFound = true;
        this.isLoadingKitsCustom = true;
        this.setIsShare(this.isShare);
        /** При монтировании получаем список наборов */
        this.isLoadingKitsCustom = true;
        this.postKitsList((kits) => {
            this.afterPostKitsList(kits);
            // this.onToggleTableKit(this.kitFoundId, 'found');

            if (this.isShare) {
                this.postCalculateSetBound([this.kitCustomIdSelected], (result) => {
                    const {bound = [], view_zoom: viewZoom = 10} = result;
                    if (bound.length) {
                        this.$refs.mapView.focusMap(
                            (bound[0][0] + bound[1][0]) / 2,
                            (bound[0][1] + bound[1][1]) / 2,
                            viewZoom
                        );
                    }

                    this.$nextTick(() => {
                        this.onToggleMarkersKit(this.kitCustomIdSelected, 'custom');
                        this.onToggleTableKit(this.kitCustomIdSelected, 'custom');
                        // this.updateLegend("custom");
                        this.onTriggerBounds();
                    });
                })

            } else {
                this.onToggleMarkersKit(this.kitFoundId);
            }
            this.isLoadingKitsCustom = false;
        });
        this.setCurrentComponent('PageBuyerdesk');
    },
    methods: {
        ...mapMutations(['setCurrentComponent', 'setIsShare']),
        ...mapMutations('pageBayuerdesk', [
            'setAllActiveMarkerStatuses',
            'setActiveMarkerStatuses',
            'setMarkersStatusesListDefault',
        ]),
        ...mapMutations('history', [
            'addHistoryItem',
        ]),
        ...mapMutations('widgetPoi', [
            'setWidgetPoi',
        ]),
        /** Получаем методы для папок */
        ...mapMutations('folders', ['toggleResizeFolder', 'closeFolder']),

        // TODO: Удалить как будут готовы уведомления
        // createNotification() {
        //     this.$notify.dNotify({
        //         type: (['default', 'success', 'error', 'warning'][Math.floor(Math.random() * (3 - 0 + 1)) + 0]),
        //         title: '<a href="https://element-plus.org/en-US/component/notification.html#notification" style="color: #4A92F6">Какая-то ссылка</a>',
        //         description: '<p>This is a <em>default</em> message!</p>',
        //         duration: Infinity,
        //         position: 'bottom-right'
        //     });
        // },

        /** Меняем состояние показа таблицы и карты */
        toggleTableAndMap() {
            const isChangedCity = this.checkMapScale();

            if (this.isWithoutPrices !== !!this.filterSelectedData.without_prices) {
                this.isWithoutPrices = !!this.filterSelectedData.without_prices;
                this.$refs.layoutFolder.forEach((folder, key) => {
                    this.$refs.layoutFolder[key].tableRows = Object.assign([])
                    this.$refs.layoutFolder[key].getTableRows();
                });
            }

            this.isLoadingKitFound = true;
            this.tableRows = [];
            this.tableFoundPageNumber = 1;
            this.postTableDataFound(this.tableFoundRequestBody, (count, rows) => {
                this.setDescriptionKitFound(this.withoutPricesSideCount);
                this.afterPostTableData(rows, 'prices', true);
                this.isLoadingKitFound = false;
            });

            // this.isLoadingMap = true;

            if (!this.isFirstStart && !isChangedCity) {
                this.onToggleMap();
            }

            if (!this.isFirstStart) {
                this.updateLegend("found");
            }

            if (this.isWithoutPrices) {
                this.updateLegend("without_prices");
            }

            this.isFirstStart = false;

            // this.refreshMarkersFound(
            //     this.$refs.mapView.getBounds(),
            //     (loadedBound) => {
            //         this.loadedBound = loadedBound;
            //         this.currentZoom = this.$refs.mapView.getZoom();
            //         this.updateLegend();
            //     }
            // );

            // this.eraseWithoutPrices();
            // this.isWithoutPrices = !!this.filterSelectedData.without_prices
            // if(this.isWithoutPrices){
            //     this.redrawWithoutPricesMarkers();
            // }
        },

        /**
         * Дергаем масштаб масштаб
         */
        onTriggerBounds() {
            try {
                this.onBoundsChanged(
                    this.$refs.mapView.getBounds(),
                    this.$refs.mapView.getZoom(),
                    true
                );
            } catch (e) {
                console.log(this.$refs.mapView)
                throw e
            }

        },

        /**
         * Меняем состояние показа карты
         */
        onToggleMap() {
            switch (true) {
                case (!this.isShare && this.isReadyMap):
                    console.log("сразу");
                    this.onTriggerBounds();
                    break;
                case (!this.isShare && !this.isReadyMap):
                    console.log("ждем");
                    this.addTrigger(() => this.onTriggerBounds());
                    break;
            }
        },

        /**
         *
         * @param {Array} bound
         * @param {Number} viewZoom
         * @param {Boolean} isGrouping
         * @param {Function} actionAfter
         */
        refreshMarkersFound(bound, viewZoom, isGrouping, actionAfter = () => {
        }) {
            this.isLoadingMap = true;
            const {latMin, latMax, lngMin, lngMax} = bound;
            const postData = {
                filter_params: this.filterSelectedData,
                bound: [
                    [latMin, lngMin],
                    [latMax, lngMax]
                ],
                radius: this.poiRadius,
                points: this.poiPoints,
                view_zoom: viewZoom,
                is_grouping: isGrouping,
                side_turn_poi: this.poiRadiusDirectionType === ''
                    ? null : this.poiRadiusDirectionType
            };
            this.isUpdateLegend = false;
            this.postMarkersDataFound(postData, async (data) => {
                const {
                    markers,
                    statuses,
                    clusters,
                    bound: loadedBound = {}
                } = data;

                this.addClusters(this.kitFoundId, clusters);

                const {id = '-1'} = this.kitFound;
                const color = this.getColorKit(id);
                const adaptedMarkers = this.adapterMarkers(markers, color, id, false, this.currentIconType)
                    .map((el) => {
                        if (el.extraData.kitId === '0') {
                            el.fill_color = el.color_legend;
                            return el;
                        }
                        return el;
                    });
                this.markers = adaptedMarkers;
                this.isUpdateLegend = true;

                this.$refs.mapView.redrawMarkers((marker) => {
                    const {extraData = {}} = marker;
                    const {kitId = '-1'} = extraData;
                    if (String(kitId) === String(this.kitFoundId))
                        return true;
                    else
                        return false;
                }, this.markers, this.kitsMarkersActive.includes(this.kitFoundId));

                this.setMarkersSelectFound(this.kitFoundId);
                this.setMarkersStatuses(statuses);
                actionAfter(loadedBound)
                this.isLoadingMap = false;
            });
        },
        async updateLegend(targetType = null) {
            this.isLoadingLegend = true;

            this.getLegendData();
            await Promise.all([
                await this.getLegendAttributes(),
                await this.getLegendGroups(targetType)
            ]);

            this.isLoadingLegend = false;

            this.updateLegendMarkersColors();
        },
        getLegendData() {
            let kitTypesMarkersActive = [...this.kitTypesMarkersActive];
            if(this.isWithoutPrices){
                kitTypesMarkersActive.push("without_prices");
            }

            if (kitTypesMarkersActive.length !== this.legendDataTypes.length) {
                const legendDataBuffer = {...this.legendData};
                this.legendData = kitTypesMarkersActive.reduce((legendData, type) => {
                    legendData[type] = legendDataBuffer[type] ? legendDataBuffer[type] : this.legendScheme[type] ?? {};
                    return legendData;
                }, {});
            }
        },
        async getLegendAttributes() {
            if (this.legendGroups.length === 0)
                await ServiceMapLegend.getLegendAttributes((legendAttributes) => this.legendGroups = [...legendAttributes], () => {});
        },

        /**
         * Получаем данные для легенды
         * @param targetType
         * @returns {Promise<void>}
         */
        async getLegendGroups(targetType = null) {
            if(targetType === null){
                this.legendItems = [];
            }else{
                const filteredLegendItems = this.legendItems.filter(item => item.legendKey !== targetType);
                this.legendItems = [...filteredLegendItems];
            }

            const isNeedUpdateFound = targetType === null || targetType === 'found';
            const isNeedUpdateCustom = targetType === null || targetType === 'custom';
            const isNeedUpdateWithoutPrices = targetType === null || targetType === 'without_prices';

            if (this.legendData.found && isNeedUpdateFound)
                await this.getLegendGroupsFound();
            if (this.legendData.custom && isNeedUpdateCustom)
                await this.getLegendGroupsCustom();
            if (this.legendData.without_prices && isNeedUpdateWithoutPrices)
                await this.getLegendGroupsWithoutPrices();
        },

        async getLegendGroupsFound() {
            await ServiceMapLegend.getLegendGroupsFound(this.filterSelectedData, String(this.legendData.found?.groupSelected ?? ''), this.kitPanelItems, (legendGroups) => {
                this.filterLegendItems('found');
                this.getLegendGroupsAfter(legendGroups);
            }, () => {});
        },

        async getLegendGroupsWithoutPrices() {
            await ServiceMapLegend.getLegendGroupsWithoutPrices(this.filterSelectedData, String(this.legendData.without_prices?.groupSelected ?? ''), this.kitPanelItems, (legendGroups) => {
                this.filterLegendItems('without_prices');
                this.getLegendGroupsAfter(legendGroups);
            }, () => {});
        },

        async getLegendGroupsCustom() {
            await ServiceMapLegend.getLegendGroupsCustom(this.kitsCustomMarkersActive, String(this.legendData.custom?.groupSelected ?? ''), this.kitPanelItems, (legendGroups) => {
                this.filterLegendItems('custom');
                this.getLegendGroupsAfter(legendGroups);
            }, () => {});
        },

        getLegendGroupsAfter(legendGroups = []) {
            this.legendItems.push(...legendGroups);
        },
        filterLegendItems(legendKey = '') {
            this.legendItems = this.legendItems.filter(legendItem => String(legendItem?.legendKey) !== String(legendKey));
        },
        updateLegendMarkersColors(targetLegendKey = null) {
            this.legendItems.forEach(legendItem => {
                const {active = false, id = '', legendKey = '', attribute = '', color = '', figure = ''} = legendItem;
                let groupId = String(id).split('--')[2] ?? '';

                if(
                    targetLegendKey !== null
                    && targetLegendKey !== legendKey
                ){
                    return;
                }

                if(legendKey === 'without_prices' && attribute === 'default'){
                    groupId = '-2';
                }

                this.$refs.mapView.updateMarkers((marker) => {
                    const {
                        extraData = {},
                        /*isRender = false, */
                        isVisible = false,
                        sidesFiltered: sidesFilteredMarker = []
                    } = marker;
                    const {kitId = '', side_names: sideNamesMarker = []} = extraData;

                    // let isDetected = false;

                    if (attribute !== 'default') {
                        const legendKeyMarker = this.getLegendKeyByKitId(kitId);

                        if (legendKeyMarker === String(legendKey)) {
                            const attributesCorrect = {
                                status: 'side_status',
                                side_name: 'side_names'
                            };
                            const attributeCorrect = attributesCorrect[attribute] ?? String(attribute);

                            // const attributeCorrect = attribute === 'status' ? 'side_status' : String(attribute);
                            const attributeDataMarker = extraData[attributeCorrect] ?? {};

                            const attributesDataMarkerCorrect = {
                                status: (attributeDataMarker) => {
                                    return Object.keys(attributeDataMarker).reduce((attributeData, side) => {
                                        attributeData[side] = [attributeDataMarker[side]];
                                        return attributeData;
                                    }, {});
                                },
                                side_name: (attributeDataMarker) => {
                                    return attributeDataMarker.reduce((attributeData, side) => {
                                        attributeData[side] = [side];
                                        return attributeData;
                                    }, {});
                                }
                            };
                            const getAttributesDataMarkerCorrect = attributesDataMarkerCorrect[attribute];
                            const attributeDataMarkerCorrect = getAttributesDataMarkerCorrect ? getAttributesDataMarkerCorrect(attributeDataMarker) : {...attributeDataMarker};

                            let isUpdated = false;
                            Object.keys(attributeDataMarkerCorrect).forEach(side => {

                                const groupIdsMarker = attributeDataMarkerCorrect[side] ?? [];
                                if (groupIdsMarker.includes(String(groupId)) && active) {
                                    marker.sidesColors[side] = String(color);
                                    marker.figure = String(figure);
                                    if(!sidesFilteredMarker.includes(String(side)))
                                        marker.sidesFiltered.push(String(side));
                                } else if (groupIdsMarker.includes(String(groupId)) && !active && sidesFilteredMarker.includes(String(side))) {
                                    const index = marker.sidesFiltered.findIndex(sideMarker => String(sideMarker) === String(side));
                                    marker.sidesFiltered.splice(index, 1);
                                }
                                this.onToggleLegendItemAfter(marker);
                                isUpdated = true;
                            });
                            if(isUpdated){
                                return;
                            }
                        }
                    } else {

                        if (String(kitId) === groupId) {
                            if (active){
                                const sides = Object.keys(marker?.sidesColors ?? {});
                                sides.forEach(side => marker.sidesColors[side] = String(color));
                                marker.figure = String(figure);
                                marker.sidesFiltered = [...sideNamesMarker];
                            } else
                                marker.sidesFiltered = [];

                            this.onToggleLegendItemAfter(marker);
                            return;
                        }
                    }

                    if (isVisible) {
                        marker.updateIcon(marker.iconType, false);
                        marker.drawMarker();
                    }else{
                        marker.clearMarker();
                    }
                });
            });
        },
        async onSelectLegendGroup(legendKey = '', groupId = '') {
            this.isLoadingLegend = true;

            this.legendData = {
                ...this.legendData,
                [legendKey]: {
                    ...this.legendData[legendKey],
                    groupSelected: String(groupId)
                }
            };

            if (legendKey === 'found')
                await this.getLegendGroupsFound();
            if (legendKey === 'custom')
                await this.getLegendGroupsCustom();
            if (legendKey === 'without_prices')
                await this.getLegendGroupsWithoutPrices();

            this.isLoadingLegend = false;

            this.updateLegendMarkersColors();
        },
        async putLegendEditStyle(type = -1, itemId = '', attribute = '', color = '', figure = '') {
            await ServiceMapLegend.putLegendEditStyle(type, itemId, attribute, color, figure, () => {
                const legendItemId = `${this.legendKeysMap[type] ?? ''}--${attribute}--${itemId}`;
                const legendItemIndex = this.legendItems.findIndex(legendItem => String(legendItem?.id ?? '') === legendItemId);
                if (legendItemIndex !== -1) {
                    const legendItemNew = {
                        ...this.legendItems[legendItemIndex],
                        color: String(color),
                        figure: String(figure)
                    };
                    this.legendItems.splice(legendItemIndex, 1, {...legendItemNew});
                }

                if (String(attribute) === 'default') {
                    const kitItemIndex = this.kitPanelItems.findIndex(kitItem => String(kitItem?.id) === String(itemId));
                    if (kitItemIndex !== -1) {
                        const kitItemNew = {
                            ...this.kitPanelItems[kitItemIndex],
                            color: `#${String(color)}`,
                        };
                        this.kitPanelItems.splice(kitItemIndex, 1, {...kitItemNew});
                    }
                }

                this.updateLegendMarkersColors();
            }, () => {});
        },

        getLegendDataEditStyle(legendData = {}) {
            const {id = '', legendKey = '', attribute = '', figure = '', color = ''} = legendData;
            const type = parseInt(this.legendTypesMap[legendKey] ?? -1);
            let itemId = String(id).split('--')[2] ?? '';

            return {type, itemId, attribute, color, figure};
        },

        async onSelectLegendColor(legendData = {}, color = ''){
            const {type = '', itemId = '', attribute = '', figure = ''} = this.getLegendDataEditStyle(legendData);
            await this.putLegendEditStyle(type, itemId, attribute, color, figure);
        },

        async onSelectLegendFigure(legendData = {}, figure = ''){
            const {type = '', itemId = '', attribute = '', color = ''} = this.getLegendDataEditStyle(legendData);
            await this.putLegendEditStyle(type, itemId, attribute, color, figure);
        },

        /**
         * Get legend key by kit id
         * @param kitId
         * @returns {string}
         */
        getLegendKeyByKitId(kitId) {
            switch (true){
                case String(kitId) === '0':
                    return  'found';
                case String(kitId) === '-2':
                    return 'without_prices';
                default:
                    return 'custom';
            }
        },

        onToggleLegendItem(legendData = {}) {

            const {legendItemId = '', active = false, group: attribute = '', legendKey = ''} = legendData;
            const legendItemIndex = this.legendItems.findIndex(legendItem => String(legendItem?.id ?? '') === String(legendItemId));
            if (legendItemIndex !== -1) {
                const legendItemNew = {
                    ...this.legendItems[legendItemIndex],
                    active: !active
                };
                this.legendItems.splice(legendItemIndex, 1, legendItemNew);

            }

            const {color = '', figure = ''} = this.legendItems[legendItemIndex];

            let groupId = String(legendItemId).split('--')[2] ?? '';

            this.$refs.mapView.updateMarkers((marker) => {
                const {extraData = {}, sidesFiltered: sidesFilteredMarker = []} = marker;
                const {kitId = '', side_names: sideNamesMarker = []} = extraData;

                if (attribute !== 'default') {
                    const legendKeyMarker = this.getLegendKeyByKitId(kitId);

                    if (legendKeyMarker === String(legendKey)) {
                        const attributesCorrect = {
                            status: 'side_status',
                            side_name: 'side_names'
                        };
                        const attributeCorrect = attributesCorrect[attribute] ?? String(attribute);
                        // const attributeCorrect = attribute === 'status' ? 'side_status' : String(attribute);
                        const attributeDataMarker = extraData[attributeCorrect] ?? {};

                        const attributesDataMarkerCorrect = {
                            status: (attributeDataMarker) => {
                                return Object.keys(attributeDataMarker).reduce((attributeData, side) => {
                                    attributeData[side] = [attributeDataMarker[side]];
                                    return attributeData;
                                }, {});
                            },
                            side_name: (attributeDataMarker) => {
                                return attributeDataMarker.reduce((attributeData, side) => {
                                    attributeData[side] = [side];
                                    return attributeData;
                                }, {});
                            }
                        };
                        const getAttributesDataMarkerCorrect = attributesDataMarkerCorrect[attribute];
                        const attributeDataMarkerCorrect = getAttributesDataMarkerCorrect ? getAttributesDataMarkerCorrect(attributeDataMarker) : {...attributeDataMarker};

                        // const attributeDataMarkerCorrect = attribute === 'status' ? Object.keys(attributeDataMarker).reduce((attributeData, side) => {
                        //     attributeData[side] = [attributeDataMarker[side]];
                        //     return attributeData;
                        // }, {}) : {...attributeDataMarker};

                        Object.keys(attributeDataMarkerCorrect).forEach((side) => {
                            const groupIdsMarker = attributeDataMarkerCorrect[side] ?? [];
                            if (groupIdsMarker.includes(String(groupId)) && !active && !sidesFilteredMarker.includes(String(side))) {
                                marker.sidesColors[side] = String(color);
                                marker.figure = String(figure);
                                marker.sidesFiltered.push(String(side));
                            } else if (groupIdsMarker.includes(String(groupId)) && active && sidesFilteredMarker.includes(String(side))) {
                                const index = marker.sidesFiltered.findIndex(sideMarker => String(sideMarker) === String(side));
                                marker.sidesFiltered.splice(index, 1);
                            }
                        });
                        this.onToggleLegendItemAfter(marker);
                    }
                } else {

                    if(legendKey === 'without_prices' && attribute === 'default'){
                        groupId = '-2';
                    }

                    if (String(kitId) === groupId) {
                        if (!active){
                            marker.sidesFiltered = [...sideNamesMarker];
                            const sides = Object.keys(marker?.sidesColors ?? {});
                            sides.forEach(side => marker.sidesColors[side] = String(color));
                            marker.figure = String(figure);
                        }
                        else
                            marker.sidesFiltered = [];

                        this.onToggleLegendItemAfter(marker);
                    }
                }
            });
        },
        onToggleLegendItemAfter(marker = {}) {
            if (marker.sidesFiltered.length > 0) {
                marker.isVisible = true;
                marker.updateIcon(marker.iconType, false);
                marker.drawMarker();
            }
            else {
                marker.isVisible = false;
                marker.clearMarker();
            }

        },
        /**
         * Ключить панораму гугл
         * @param {String} lat - широта
         * @param {String} lng - долгота
         */
        onPanoramaGoogle(lat = null, lng = null) {
            this.$refs.mapView.setStreetView(lat, lng);
        },

        /**
         * Перерисовать маркеры без цен
         */
        redrawWithoutPricesClusters(bound, viewZoom, isGrouping) {
            const {latMin, latMax, lngMin, lngMax} = bound;
            const postData = {
                filter_params: this.filterSelectedData,
                bound: [
                    [latMin, lngMin],
                    [latMax, lngMax]
                ],
                radius: this.poiRadius,
                points: this.poiPoints,
                view_zoom: viewZoom,
                is_grouping: isGrouping
            };
            this.postClustersDataWithoutPrices(postData, (data) => {
                const {
                    markers,
                    clusters
                } = data;

                const kitId = '-2';
                this.addClusters(kitId, clusters);

                const color = '#848484';
                this.markersWithoutPrices = this.adapterMarkers(markers, color, kitId, false, this.currentIconType)
                    .map((marker) => {
                        return this.prepareWithoutPriceMarker(marker, color)
                    });

                this.$refs.mapView.redrawMarkers((marker) => {
                    const {extraData = {}} = marker;
                    const {kitId = '-1'} = extraData;
                    return String(kitId) === '-2';
                }, this.markersWithoutPrices, 1);

                this.updateLegendMarkersColors("without_prices");
            });
        },

        /**
         * @param {Object} marker
         * @param {String} color
         * @returns {Object}
         */
        prepareWithoutPriceMarker(marker, color) {
            let selected = false;
            Object.values(marker.catalog_sides).forEach((catalogId) => {
                if(Object.keys(this.mapWithoutPriceSelectedCatalogIds).includes(catalogId)){
                    selected = true;
                }
            })
            marker.selected = selected;
            marker.status = 'without_price';
            marker.extraData.status = marker.status;
            marker.extraData.catalogSides = marker.catalog_sides
            marker.fill_color = color;
            return marker;
        },

        /**
         * Нарисовать маркеры конструкций без цен
         */
        redrawWithoutPricesMarkers() {
            const postData = {filter_params: this.filterSelectedData};
            for (let i = 0; i < this.withoutPricesStepCount;i++){
                postData.step = i;
                this.isLoadingMap = true;
                this.postMarkersDataWithoutPrices(postData, (markers) => {
                    const id = '-2';
                    const color = '#848484';
                    this.markersWithoutPrices = this.adapterMarkers(markers, color, id, false, this.currentIconType)
                        .map((marker) => {
                            return this.prepareWithoutPriceMarker(marker, color)
                        });
                    this.$refs.mapView.redrawMarkers((marker) => {
                        const {extraData = {}} = marker;
                        const {kitId = '-1'} = extraData;
                        if (String(kitId) === '-2')
                            return true;
                        else
                            return false;
                    }, this.markersWithoutPrices, 1);
                    this.isLoadingMap = false;
                });
            }
        },

        /**
         * Очистить маркеры без цен
         */
        eraseWithoutPrices() {
            this.$refs.mapView.redrawMarkers((marker) => {
                const {extraData = {}} = marker;
                const {kitId = '-3'} = extraData;
                return String(kitId) === String('-2');
            }, [], 1);
        },
        /**
         * Событие выделения набора
         * @param {String} id - идентификатор элемента набора
         * @param {String} type - тип элемента набора (found|custom)
         */
        onSelectKit(id = '-1', type = '') {
            if (id !== this.kitFoundId && id !== this.kitCustomIdSelected) {
                if (this.kitsMarkersActive.includes(String(this.kitCustomIdSelected)))
                    this.onToggleMarkersKit(this.kitCustomIdSelected, 'custom');
                if (this.kitsFolderIdsActive.includes(this.kitCustomIdSelected))
                    this.onToggleTableKit(this.kitCustomIdSelected, 'custom');
                this.selectKit(id, type);
                this.onToggleMarkersKit(id, type);
                this.onToggleTableKit(id, type);
                this.bringToFront(id);
            }
        },
        /**
         * Событие создания набора
         */
        onCreateKit() {
            console.log('createKit');
            const newKit = this.createKit();
            this.postCreateKit(newKit, (kit) => {
                this.afterPostCreateKit(kit);
            });
        },
        /**
         * Событие переименования набора
         * @param {String} id - идентификатор набора
         * @param {String} newName - новое название
         */
        onRenameKit(id = '-1', newName = '') {
            const postData = {set_id: id, name: newName};
            this.postRenameKit(postData, (id, newName) => {
                this.afterPostRenameKit(String(id), String(newName));
            });
        },
        /**
         * Событие переключение показа таблицы набора
         * @param {String} id - идентификатор набора
         * @param {String} type - тип набора
         */
        onToggleTableKit(id = '-1', type = '') {
            if (id !== this.kitFoundId && id !== this.kitCustomIdSelected) {
                if (this.kitsFolderIdsActive.includes(this.kitCustomIdSelected))
                    this.onToggleTableKit(this.kitCustomIdSelected, 'custom');
            }
            this.selectKit(id, type);
            this.toggleFolder(String(id), type);
        },
        /**
         * Событие переключения показа маркеров набора
         * @param {String} id - идентификатор набора
         * @param {String} type - тип набора
         */
        onToggleMarkersKit(id = '-1', type = '') {
            this.isLoadingMap = false;
            this.selectKit(id, type);
            if (!this.kitsMarkersActive.includes(String(id))) {
                this.kitsMarkersActive.push(String(id));

                // if (String(id) === this.kitFoundId && this.loadedBound !== null) {
                //     this.markersStatuses = this.markersStatusesListDefault;
                //     this.setActiveMarkerStatuses(this.allActiveLegendPanelMarkersStatuses);
                //
                //     this.refreshMarkersFound(
                //         this.$refs.mapView.getBounds(),
                //         (loadedBound) => {
                //             this.loadedBound = loadedBound;
                //             this.currentZoom = this.$refs.mapView.getZoom();
                //             // this.updateLegend();
                //             this.onChangeMarkers();
                //         }
                //     );
                //
                // } else if(String(id) !== this.kitFoundId){
                //     const index = this.kitCustoms.findIndex(kit => String(kit.id) === String(id));
                //     this.isLoadingMap = true;
                //
                //     const bound = this.$refs.mapView.getBounds();
                //     this.$refs.layoutFolder[index].getMarkersAndClusters(bound, (loadedBound) => {
                //         this.loadedBound = loadedBound;
                //         this.currentZoom = this.$refs.mapView.getZoom();
                //     });
                // }
            }
            else {
                this.kitsMarkersActive.splice(this.kitsMarkersActive.findIndex(kitId => String(kitId) === String(id)), 1);

                if (String(id) === this.kitFoundId) {
                    if (this.activeLegendPanelMarkersStatuses.includes('without_price')) {
                        this.markersStatuses = this.onlyWithoutPrice;
                        this.setActiveMarkerStatuses(['without_price']);
                    }
                    if (!this.activeLegendPanelMarkersStatuses.includes('without_price')) {
                        this.markersStatuses = this.onlyWithoutPrice;
                        this.setActiveMarkerStatuses([]);
                    }

                    this.clearMarkersFound(this.kitFoundId);
                    this.removeClusters(this.kitFoundId);
                    this.onChangeMarkers();
                } else {
                    this.clearMarkersFound(id);
                    this.removeClusters(id);
                }

            }

            this.onToggleMap();
        },
        /**
         * Событие ленивой загрузки найдено
         */
        onLazyloadTableDataFound() {
            this.isProgressBarLoading = true;
            this.tableFoundPageNumber += 1;
            this.postTableDataFound(this.tableFoundRequestBody, (_, rows) => {
                this.afterLazyloadTableDataFound(rows);
                this.isProgressBarLoading = false;
            });
        },
        /**
         * Событие фильтрации по столбцам найдено
         * @param {Object} dataFilter - данные фильтра по столбцам
         */
        onFilterTableDataFound(dataFilter = {}) {
            this.beforeFilterTable(dataFilter);
            this.tableFoundPageNumber = 1;
            this.postTableDataFound(this.tableFoundRequestBody, (_, rows) => {
                this.afterPostTableData(rows, 'prices', true);
            });
        },
        /**
         * Событие сортировки в найдено
         * @param {Object} dataSort - данные сортировки
         */
        onSortTableDataFound(dataSort = {}) {
            this.beforeSortTable(dataSort);
            this.tableFoundPageNumber = 1;
            this.postTableDataFound(this.tableFoundRequestBody, (_, rows) => {
                this.afterPostTableData(rows, 'prices', true);
            });
        },
        /**
         * Событие массового выбора строк найдено
         * @param {Boolean} isSelected - значение флага массового выбора
         */
        onCheckRowsAllFound(isSelected = false) {
            const rowsIds = this.tableRows.map(row => {
                const {id = ''} = row;
                return String(id);
            });
            const priceIds = this.tableRows.reduce((priceIdsAccumulator, row) => {
                const {prices = []} = row;
                const priceIdsCurrent = prices.filter(price => {
                    return isSelected ? String(price?.status ?? '') !== 'busy' : true;
                }).map(price => {
                    const {id = ''} = price;
                    return String(id);
                });

                return [...priceIdsAccumulator, ...priceIdsCurrent];
            }, []);
            const action = isSelected ? 'add' : 'remove';
            const postData = {set_id: this.kitCustomIdSelected, action, ids: priceIds};
            this.postSyncDataCustom(postData, () => {
                const postData = {set_id: this.kitCustomIdSelected, selected: rowsIds};
                this.postTableDataRow(postData, (rows, kitItem) => {
                    this.updateKit(kitItem);
                    rowsIds.forEach(rowId => {
                        this.afterPostTableDataRow(rows, action, rowId);
                    });
                    const sideIds = this.cardData.map(card => String(card.id));
                    this.updateCardMarkersSelected(sideIds, this.getSelectedTableRows());
                });
            });
        },
        getSelectedTableRows(){
            const {extraData = {}} = this.cardMarker;
            const {kitId = '-1'} = extraData;
            let tableRowsPricesIdsSelected;
            let index = this.kitCustoms.findIndex(kit => String(kit.id) === String(this.kitCustomIdSelected));
            if(kitId == '-2'){
                tableRowsPricesIdsSelected  = this.$refs.layoutFolder[index].tableRowsCatalogIdsPricesSelected || {};
            }else{
                // let index = this.kitCustoms.findIndex(kit => String(kit.id) === String(String(kitId) === this.kitFoundId ? this.kitCustomIdSelected : kitId));
                tableRowsPricesIdsSelected = this.$refs.layoutFolder[index].tableRowsPricesIdsSelected || [];
            }
            return tableRowsPricesIdsSelected;
        },
        /**
         * Событие выбора строки найдено
         * @property {Object} checkData - данные выбора
         */
        onCheckRowFound(checkData = {}) {
            const {rowIsSelected = false, row = {}} = checkData;
            const {id: rowId = -1, prices = []} = row;
            const currentPricesSelected = this.tableCustomPricesSelected[rowId] || [];
            const priceIds = prices.filter(price => {
                const {date = ''} = price;
                const isPriceBusy = String(price?.status ?? '') === 'busy';
                if (rowIsSelected)
                    return !currentPricesSelected.includes(date) && !isPriceBusy;
                else
                    return currentPricesSelected.includes(date);
            }).map(price => price.id);
            const action = rowIsSelected ? 'add' : 'remove';
            const postData = {set_id: this.kitCustomIdSelected, action, ids: priceIds};
            this.postSyncDataCustom(postData, () => {
                const postData = {set_id: this.kitCustomIdSelected, selected: [rowId]};
                this.postTableDataRow(postData, (rows, kitItem) => {
                    this.updateKit(kitItem);
                    this.afterPostTableDataRow(rows, action, rowId);
                    const sideIds = this.cardData.map(card => String(card.id));
                    this.updateCardMarkersSelected(sideIds, this.getSelectedTableRows());
                });
            });
        },
        /**
         * Событие выбора цены в наборе
         * @param {Object} checkData - данные выбора
         * @param {String} keyPrices - ключ по которому вытаскиваются цены
         * @param {String} allPrices - ключ для добавления/удаления всех цен в строке
         */
        onCheckSubCheckboxFound(checkData = {}, keyPrices = 'prices', allPrices = '') {
            if (!this.isShare) {
                const {postData = {}, rowId = '-1', action = ''} = this.beforePostSyncDataCustom(checkData, this.tableCustomPricesSelected, this.kitCustomIdSelected, keyPrices, allPrices);
                this.postSyncDataCustom(postData, () => {
                    const postData = {set_id: this.kitCustomIdSelected, selected: [rowId]};
                    this.postTableDataRow(postData, (rows, kitItem) => {
                        this.updateKit(kitItem);
                        this.afterPostTableDataRow(rows, action, rowId);
                        const sideIds = this.cardData.map(card => String(card.id));
                        this.updateCardMarkersSelected(sideIds, this.getSelectedTableRows());
                    });
                });
            }
        },
        /**
         * Событие выбора всего найденного
         */
        onCheckAddAll() {
            if(this.kitFound.description > 1000){
                alert('Нельзя выбрать больше 1000 конструкций');
                return;
            }
            const postData = {set_id: this.kitCustomIdSelected, action: 'add-all', ids: ['1']};
            this.postSyncDataCustom(postData, () => {
                const postData = {set_id: this.kitCustomIdSelected};
                this.postTableDataRow(postData, (rows, kitItem) => {
                    this.updateKit(kitItem);
                    const indexFolder = this.kitCustoms.findIndex(kit => String(kit.id) === String(this.kitCustomIdSelected));
                    this.$refs.layoutFolder[indexFolder].clearRowTable();
                    this.afterPostTableDataRow(rows, 'add');
                    const sideIds = this.cardData.map(card => String(card.id));
                    this.updateCardMarkersSelected(sideIds, this.getSelectedTableRows());
                });
            });
        },
        beforeGetMarkers() {
            this.isUpdateLegend = false;
        },
        /**
         * Событие сработающее после получения маркеров набора
         * @param {String} kitId - идентификатор набора
         * @param {Array} markers - массив маркеров
         * @param {Array} clusters - массив кластеров
         */
        async afterGetMarkersCustom(kitId = '-1', markers = [], clusters = []) {
            const markersRender = markers.map(marker => {
                return {
                    ...marker,
                    selected: false
                };
            });
            this.isUpdateLegend = true
            this.addClusters(kitId, clusters);
            this.$refs.mapView.redrawMarkers((marker) => {
                const {extraData = {}} = marker;
                const {kitId: markerKitId = '-1'} = extraData;
                if (String(markerKitId) === String(kitId))
                    return true;
                else
                    return false;
            }, markersRender, this.kitsMarkersActive.includes(String(kitId)), false);
            // await this.updateLegend();
            this.updateLegendMarkersColors("custom");
            this.isLoadingMap = false;
        },
        /**
         * Событие, сработающее после запроса на получения данных для строки при добавлении в набор
         * @property {Array} rows - строки
         * @property {String} action - действие
         * @property {String} rowId - идентификатор строки
         */
        afterPostTableDataRow(rows = [], action = '', rowId = '-1') {
            if (rows.length !== 0)
                this.updateTableCustom(rows, action);
            else {
                const index = this.kitCustoms.findIndex(kit => String(kit.id) === String(this.kitCustomIdSelected));
                this.$refs.layoutFolder[index].removeRowTable(rowId);
            }
        },
        /**
         * Обновить данные таблицы набора
         * @property {Array} rows - строки
         * @property {String} action - действие
         */
        updateTableCustom(rows = [], action = '') {
            const index = this.kitCustoms.findIndex(kit => String(kit.id) === String(this.kitCustomIdSelected));
            if (action === 'add')
                this.$refs.layoutFolder[index].addPriceTable(rows);
            else if (action === 'remove')
                this.$refs.layoutFolder[index].removePriceTable(rows);
        },
        /**
         * Обновить маркеры найдено
         */
        setMarkersSelectFound(targetKitId, targetMarkers = null, bufferMarkersSelected = null) {
            this.$refs.mapView.updateMarkers((marker) => {
                const {id = '-1', extraData = {}, state = -1} = marker;
                const {kitId = '-1'} = extraData;
                if (String(kitId) === String(targetKitId) && state === 1 && targetMarkers === null) {
                    // console.log('0', this.kitsMarkersActive.includes(targetKitId));
                    marker.updateState(0, this.kitsMarkersActive.includes(targetKitId));
                }else if(String(kitId) === String(targetKitId) && targetMarkers !== null && targetMarkers.includes(id) && !bufferMarkersSelected.includes(id)){
                    // console.log('0', targetKitId, this.kitsMarkersActive.includes(targetKitId));
                    marker.updateState(0, this.kitsMarkersActive.includes(targetKitId));
                }
                if (String(kitId) === String(targetKitId) && this.mapFoundMarkersSelectedIds.includes(String(id)) && targetMarkers === null) {
                    // console.log('1', this.kitsMarkersActive.includes(targetKitId));
                    marker.updateState(1, this.kitsMarkersActive.includes(targetKitId));
                }else if(String(kitId) === String(targetKitId) && targetMarkers !== null && targetMarkers.includes(id) && bufferMarkersSelected.includes(id)){
                    // console.log('1', targetKitId, this.kitsMarkersActive.includes(targetKitId));
                    marker.updateState(1, this.kitsMarkersActive.includes(targetKitId));
                }
            }, false);
        },
        /**
         * Обновить маркеры без цен
         */
        setMarkersSelectWithoutPrice() {
            this.$refs.mapView.updateMarkers((marker) => {
                const {extraData = {}, state = -1} = marker;
                const {kitId = '-1', catalogSides = {}} = extraData;
                let isSelected = false;
                Object.values(catalogSides).forEach((catalogId) => {
                    if(Object.keys(this.mapWithoutPriceSelectedCatalogIds).includes(catalogId)){
                        isSelected = true;
                    }
                });
                if (String(kitId) === '-2' && !isSelected && state === 1) {
                    marker.updateState(0, this.filterSelectedData.without_prices);
                }
                if (String(kitId) === '-2' && isSelected) {
                    marker.updateState(1, this.filterSelectedData.without_prices);
                }
            }, false);
        },
        /**
         * Нарисовать маркеры найдено
         * @param {String} kitId - идентификатор набора
         */
        drawMarkersFound(kitId = '-1') {
            this.isLoadingMap = false;
            this.$refs.mapView.updateMarkers((marker) => {
                const {extraData = {}} = marker;
                const {kitId: markerKitId = '-1', type = 'default'} = extraData;
                if (type !== 'poi' && String(markerKitId) === String(kitId)) {
                    marker.drawMarker();
                }
            });
        },

        /**
         * Очистить маркеры найдено с карты
         * @param {String} currentKitId - идентификатор набора
         */
        clearMarkersFound(currentKitId = '-1') {
            this.markers = [];
            this.$refs.mapView.redrawMarkers((marker) => {
                const {extraData = {}} = marker;
                const {kitId = '-1'} = extraData;

                if (String(kitId) === String(currentKitId))
                    return true;
                else
                    return false;
            }, [], true);
        },

        /**
         * Выбрать маркера
         * @param {Object} sideIds - стороны для выбора
         * @param {String} kitId - идентификатор набора
         * @param {String} state - состояние маркеров (выбраны / не выбраны)
         * @param {Boolean} withoutPrices
         * @param {String} addAction
         * @param {Array<String>} quadkeys массив ключей кластеров
         */
        selectMarkers(sideIds = [], kitId = '-1', state = 0, withoutPrices = false, addAction = 'add-sides', quadkeys = []) {
            if(state){
                this.removeMarkerSides(sideIds, kitId, withoutPrices);
            }else{
                this.addMarkerPrices(sideIds, kitId, addAction, quadkeys);
            }
        },

        /**
         * Удаление конструкций из набора
         * @param {Object} sideIds - стороны для выбора
         * @param {String} kitId - идентификатор набора
         * @param {Boolean} withoutPrices
         */
        removeMarkerSides(sideIds, kitId, withoutPrices){
            const action = withoutPrices ? 'catalog-sides-remove' :'remove-sides';
            const postData = {set_id: String(kitId) === this.kitFoundId ? this.kitCustomIdSelected : kitId, action, ids: sideIds};
            this.postSyncDataCustom(postData, () => {
                const index = this.kitCustoms.findIndex(kit => String(kit.id) === String(this.kitCustomIdSelected));
                this.$refs.layoutFolder[index].addPriceTable();

                const sideIdsGlobal = this.cardData.map(card => String(card.id));
                this.updateCardMarkersSelected(sideIdsGlobal, this.getSelectedTableRows());

                // const postData = {set_id: String(kitId) === this.kitFoundId ? this.kitCustomIdSelected : kitId, selected: sideIds};
                // this.postTableDataRow(postData, (rows, kitItem) => {
                //     this.updateKit(kitItem);
                //     if(rows.length === 0) {
                //         sideIds.forEach(rowId => {
                //             this.afterPostTableDataRow(rows, action, rowId);
                //         });
                //     }
                //     else {
                //         this.afterPostTableDataRow(rows, action, '-1');
                //     }
                //     const sideIdsGlobal = this.cardData.map(card => String(card.id));
                //     this.updateCardMarkersSelected(sideIdsGlobal, this.getSelectedTableRows());
                // });
            });
        },

        /**
         * Добавление размещений к набору
         * @param {Object} sideIds - стороны для выбора
         * @param {String} kitId - идентификатор набора
         * @param {String} addAction
         * @param {Array<String>} quadkeys массив ключей кластеров
         */
        addMarkerPrices(sideIds, kitId, addAction, quadkeys){
            // const action = 'add';
            const {latMin, latMax, lngMin, lngMax} = this.$refs.mapView.getBounds();
            const postData = {
                set_id: String(kitId) === this.kitFoundId ? this.kitCustomIdSelected : kitId,
                action: addAction,
                ids: sideIds,
                view_zoom: this.currentViewZoom,
                bound: [
                    [latMin, lngMin],
                    [latMax, lngMax]
                ],
                quadkeys,
                radius: this.poiRadius,
                points: Object.values(this.poiPointsDetailed),
                side_turn_poi: this.poiRadiusDirectionType === ''
                    ? null : this.poiRadiusDirectionType
            };

            this.postSyncDataCustom(postData, () => {
                const index = this.kitCustoms.findIndex(kit => String(kit.id) === String(this.kitCustomIdSelected));
                this.$refs.layoutFolder[index].addPriceTable();

                const sideIdsGlobal = this.cardData.map(card => String(card.id));
                this.updateCardMarkersSelected(sideIdsGlobal, this.getSelectedTableRows());

                // const postData = {set_id: String(kitId) === this.kitFoundId ? this.kitCustomIdSelected : kitId, selected: sideIds};
                // this.postTableDataRow(postData, (rows, kitItem) => {
                //     this.updateKit(kitItem);
                //     if(rows.length === 0) {
                //         sideIds.forEach(rowId => {
                //             this.afterPostTableDataRow(rows, action, rowId);
                //         });
                //     }
                //     else {
                //         this.afterPostTableDataRow(rows, action, '-1');
                //     }
                //     const sideIdsGlobal = this.cardData.map(card => String(card.id));
                //     this.updateCardMarkersSelected(sideIdsGlobal, this.getSelectedTableRows());
                // });
            });
        },

        /**
         * Событие массового выбора маркеров
         * @param {Array} markers - маркеры
         * @param {Array} clusters - кластеры
         */
        onMassSelectMarkers(markers = [], clusters = []) {
            const quadKeys = clusters.map(cluster => cluster.quadkey);
            const markersRender = markers.filter(marker => Boolean(marker?.isRender ?? false) && Boolean(marker?.isVisible ?? false));
            const kitId = '0';
            let updateMarkers = [];
            let updateMarkersCatalog = [];
            const markersNoSelected = markersRender.filter((marker) => {
                const {extraData = {}/*, state = 0*/, id = null} = marker;
                const {kitId: kitIdMarker = '-1'} = extraData;
                if(/*parseInt(state) === 0 && */String(kitIdMarker) === kitId){
                    updateMarkers.push(id);
                    return true;
                }else{
                    return false;
                }
            });
            const markersWithoutPrice = markersRender.filter((marker) => {
                const {extraData = {}, state = 0, id = null} = marker;
                const {kitId: kitIdMarker = '-1'} = extraData;
                if(parseInt(state) === 0 && String(kitIdMarker) === '-2'){
                    updateMarkersCatalog.push(id);
                    return true;
                }else{
                    return false;
                }
            });
            if(markersWithoutPrice.length > 100){
                alert('Нельзя добавить в набор более 100 конструкций без цен');
                return false;
            }
            this.setMarkersSelectFound(this.kitFoundId, updateMarkers, updateMarkers);
            this.setMarkersSelectFound(-2, updateMarkersCatalog, updateMarkersCatalog);
            const catalogSideIds = markersWithoutPrice.reduce((sideIdsUnion, marker) => {
                const {extraData = {}} = marker;
                const {catalogSides = []} = extraData;
                const sideIdsAdded = Object.values(catalogSides).map(sideId => String(sideId)).filter(sideId => !sideIdsUnion.includes(sideId));
                return [...sideIdsUnion, ...sideIdsAdded];
            }, []);
            const sideIds = markersNoSelected.reduce((sideIdsUnion, marker) => {
                const {extraData = {}} = marker;
                const {sideIds = []} = extraData;
                const sideIdsAdded = sideIds.map(sideId => String(sideId)).filter(sideId => !sideIdsUnion.includes(sideId));
                return [...sideIdsUnion, ...sideIdsAdded];
            }, []);
            if(catalogSideIds.length > 0){
                this.selectWithoutPriceSides(catalogSideIds)
            }

            if (sideIds.length > 0 || quadKeys.length > 0)
                this.selectMarkers(
                    sideIds,
                    kitId,
                    0,
                    false,
                    'add-side-slots',
                    quadKeys
                );
        },

        /**
         * Функция выбора конструкий без цен
         **/
        selectWithoutPriceSides(catalogSideIds){
            const postData = {set_id: this.kitCustomIdSelected, action: 'catalog-sides-add', ids: catalogSideIds};
            this.postSyncDataCustom(postData, (sideIds) => {
                const postData = {set_id: this.kitCustomIdSelected, selected:sideIds};
                this.postTableDataRow(postData, (rows, kitItem) => {
                    this.updateKit(kitItem);
                    if(rows.length){
                        this.afterPostTableDataRow(rows, 'add', '-1');
                    }
                    const sideIdsGlobal = this.cardData.map(card => String(card.id));
                    this.updateCardMarkersSelected(sideIdsGlobal, this.getSelectedTableRows());
                });
            });
        },
        switchWithoutPriceSelection(marker)
        {
            const {extraData = {}} = marker;
            if(
                Object.values(extraData.catalogSides).length < 2
                && !Object.keys(this.mapWithoutPriceSelectedCatalogIds).includes(Object.values(extraData.catalogSides)[0])
            ){
                this.setMarkersSelectFound('-2',[marker.id], [marker.id]);
                this.selectWithoutPriceSides(Object.values(extraData.catalogSides));
                return false;
            }else if(
                Object.values(extraData.catalogSides).length < 2
                && Object.keys(this.mapWithoutPriceSelectedCatalogIds).includes(Object.values(extraData.catalogSides)[0])
            ){
                let sideIdsToRemove = [];
                Object.values(extraData.catalogSides).forEach((catalogSideId) => {
                    if(Object.keys(this.mapWithoutPriceSelectedCatalogIds).includes(catalogSideId)){
                        sideIdsToRemove.push(this.mapWithoutPriceSelectedCatalogIds[catalogSideId]);
                    }
                })
                this.selectMarkers(sideIdsToRemove, this.kitCustomIdSelected, 1, true);
                return false;
            }
            // else {
            //     this.onSelectMarkerRight(marker)
            // }
            let sideList = [];
            Object.keys(extraData.catalogSides).forEach((code) => {
                sideList.push({
                    name: code,
                    value: extraData.catalogSides[code],
                    checked: Object.keys(this.mapWithoutPriceSelectedCatalogIds).includes(extraData.catalogSides[code])
                });
            });
            sideList = sideList.sort((strA, strB) => {
                if (strA.name > strB.name)
                    return 1;
                if (strA.name < strB.name)
                    return -1;
                return 0;
            });
            this.currentSelectionSideMode = 'without_prices';
            this.currentSideCheckList = sideList;
            this.$refs.mapView.initSideSelectionBox({lat:marker.lat, lng: marker.lng}, sideList)
        },
        /**
         * Событие выбора маркера
         * @param {Object} marker - маркер
         */
        onSelectMarker(marker = {}) {
            if (!this.isShare) {
                const {extraData = {}, state = 0} = marker;
                const {kitId = '-1'} = extraData;
                let resultState =  kitId > 0 ? 1 : state;
                if(kitId == '-2'){
                    this.switchWithoutPriceSelection(marker);
                    return false;
                }

                if (marker.extraData.type !== 'poi') {
                    if(extraData.sideIds.length < 2){
                        this.setMarkersSelectFound(this.kitFoundId,[marker.id], resultState ? [] : [marker.id]);
                        this.selectMarkers(extraData.sideIds, kitId, resultState);
                    }
                    // else {
                    //     this.onSelectMarkerRight(marker)
                    // }
                    // return true;
                    const postData = {selectedMarker: [marker.id]};
                    let sideList = [];
                    let pushedSides = [];
                    let index = this.kitCustoms.findIndex(kit => String(kit.id) === this.kitCustomIdSelected);
                    const tableRowsPricesIdsSelected = this.$refs.layoutFolder[index].tableRowsPricesIdsSelected || [];
                    const kitRows = this.$refs.layoutFolder[index].tableRows || [];
                    this.postTableDataRowFound(postData, (rows) => {
                        rows.forEach(row => {
                            pushedSides.push(row.id);
                            sideList.push({
                                name: row.full_code,
                                value: row.id,
                                checked: Object.keys(tableRowsPricesIdsSelected).includes(row.id)
                            });
                        });
                        kitRows.filter(item => item.marker_id === marker.id).forEach(row => {
                            if(!pushedSides.includes(row.id)){
                                pushedSides.push(row.id);
                                sideList.push({
                                    name: row.full_code,
                                    value: row.id,
                                    checked: Object.keys(tableRowsPricesIdsSelected).includes(row.id)
                                });
                            }
                        });
                        sideList = sideList.sort((strA, strB) => {
                            if (strA.name > strB.name)
                                return 1;
                            if (strA.name < strB.name)
                                return -1;
                            return 0;
                        })
                        if(sideList.length < 2){
                            this.selectMarkers(pushedSides, kitId, state);
                            return true;
                        }
                        this.currentSelectionSideMode = 'default';
                        this.currentSideCheckList = sideList;
                        this.$refs.mapView.initSideSelectionBox({lat:marker.lat, lng: marker.lng}, sideList);
                    });
                }
                if (marker.extraData.type === 'poi') {
                    const poiInfo = {
                        address: marker.extraData.address,
                        description: marker.extraData.description,
                    };
                    this.$refs.mapView.initWindowInfoPoi({lat:marker.lat, lng: marker.lng}, poiInfo);
                }

            }
        },
        /**
         * Событие выбора стороны в мелком окошке
         * @param {Object} data
         */
        onAfterSelectSideInBox(data){
            let {sideIds = [], state} = data;
            let currentSideCheckList = [...this.currentSideCheckList];
            currentSideCheckList.forEach((item) => {
                sideIds.forEach((sideId) => {
                    if(sideId === item.value){
                        item.checked = !state
                    }
                });
            })
            this.currentSideCheckList = [...currentSideCheckList];
            if(this.currentSelectionSideMode === 'without_prices' && state === 1){
                let sideIdsToRemove = [];
                sideIds.forEach((catalogSideId) => {
                    if(Object.keys(this.mapWithoutPriceSelectedCatalogIds).includes(catalogSideId)){
                        sideIdsToRemove.push(this.mapWithoutPriceSelectedCatalogIds[catalogSideId]);
                    }
                })
                let markerIds = this.getMarkerWPIdsBySidesInBox(sideIds, state);
                this.setMarkersSelectFound('-2', markerIds, [])
                this.selectMarkers(sideIdsToRemove, this.kitCustomIdSelected, state, true);
            }else if(this.currentSelectionSideMode === 'default'){
                let markerIds = this.getMarkerFoundIdsBySidesInBox(sideIds, state);
                this.setMarkersSelectFound(this.kitFoundId, markerIds, state ? [] : markerIds)
                this.selectMarkers(sideIds, this.kitCustomIdSelected, state);
            }else {
                let markerIds = this.getMarkerWPIdsBySidesInBox(sideIds, state);
                this.setMarkersSelectFound('-2', markerIds, markerIds)
                this.selectWithoutPriceSides(sideIds);
            }
        },
        /**
         * Событие правого клика по маркеру
         * @param {Object} marker - маркеры
         */
        onSelectMarkerRight(marker = {}) {
            const {id = '-1', extraData = {}} = marker;
            const {kitId = '-1', sideIds = [], catalogSides = {}} = extraData;
            if(kitId == '-2'){
                let index = this.kitCustoms.findIndex(kit => String(kit.id) === String(this.kitCustomIdSelected));
                const tableRowsPricesIdsSelected = this.$refs.layoutFolder[index].tableRowsCatalogIdsPricesSelected || {};
                let tableRowPricesIdsSelected = Object.assign({}, tableRowsPricesIdsSelected);
                Object.keys(tableRowsPricesIdsSelected).forEach(rowId => {
                    if (!Object.values(catalogSides).includes(String(rowId)))
                        delete tableRowPricesIdsSelected[rowId];
                });
                const postData = {marker_id: id}
                this.postMarkerCatalogData(postData, (rows) => {
                    this.isCardVisible = true;
                    this.cardData = rows;
                    this.cardMarker = marker;
                    this.cardMarkerSelected = tableRowPricesIdsSelected;
                    let index = this.kitCustoms.findIndex(kit => String(kit.id) === this.kitCustomIdSelected);
                    this.cardKitPrices = this.$refs.layoutFolder[index].tableCatalogPrices || {};
                });
            }else {
                let index = this.kitCustoms.findIndex(kit => String(kit.id) === String((String(kitId) === this.kitFoundId) ? this.kitCustomIdSelected : kitId));
                const tableRowsPricesIdsSelected = this.$refs.layoutFolder[index].tableRowsPricesIdsSelected || {};
                let tableRowPricesIdsSelected = Object.assign({}, tableRowsPricesIdsSelected);
                Object.keys(tableRowsPricesIdsSelected).forEach(rowId => {
                    if (!sideIds.includes(String(rowId)))
                        delete tableRowPricesIdsSelected[rowId];
                });
                const postData = {marker_id: id, set_id: kitId > 0 ? kitId : null}
                this.postMarkerData(postData, (rows) => {
                    this.isCardVisible = true;
                    this.cardData = rows;
                    this.cardMarker = marker;
                    this.cardMarkerSelected = tableRowPricesIdsSelected;
                    this.cardKitPrices = this.$refs.layoutFolder[index].tableRowsPrices || {};
                });
            }

        },
        /**
         * Выбор цены в карточке
         * @param {String} priceId - id цены
         * @param {String} sideId - id стороны
         */
        onCheckPrice(priceId = '-1', sideId = '-1') {
            if (!this.isShare) {
                const {extraData = {}} = this.cardMarker;
                // const {kitId = '-1', sideIds = []} = extraData;
                let {kitId = '-1'} = extraData;
                const sideIds = this.cardData.map(card => String(card.id));
                const {pricesReduced = []} = this.afterPostTableDataRowFound(this.cardData);
                const pricesFiltered = pricesReduced.filter(id => String(id) === String(priceId));
                let tableRowPricesIdsSelected;
                let tableRowSideIdSelected;
                let tableRowsSideIdsSelected;
                let isCatalog = false;

                if(kitId === '-2'){
                    isCatalog = true;
                    kitId = this.kitFoundId;
                    let index = this.kitCustoms.findIndex(kit => String(kit.id) === String(this.kitCustomIdSelected));
                    const tableRowsPricesIdsSelected = this.$refs.layoutFolder[index].tableRowsCatalogIdsPricesSelected || [];
                    tableRowsSideIdsSelected = this.$refs.layoutFolder[index].tableRowsCatalogIdsSelected || [];
                    tableRowPricesIdsSelected = tableRowsPricesIdsSelected[sideId] || [];
                } else {
                    let index = this.kitCustoms.findIndex(kit => String(kit.id) === String(String(kitId) === this.kitFoundId ? this.kitCustomIdSelected : kitId));
                    const tableRowsPricesIdsSelected = this.$refs.layoutFolder[index].tableRowsPricesIdsSelected || [];
                    tableRowPricesIdsSelected = tableRowsPricesIdsSelected[sideId] || [];
                }
                let action;

                if(!tableRowPricesIdsSelected.includes(String(priceId)) && isCatalog){
                    tableRowSideIdSelected = sideId;
                    action = 'catalog-sides-add';
                } else if(!tableRowPricesIdsSelected.includes(String(priceId)) && !isCatalog){
                    tableRowSideIdSelected = sideId;
                    action = 'add';
                } else if(isCatalog){
                    tableRowSideIdSelected = tableRowsSideIdsSelected[sideId];
                    action = 'remove';
                } else {
                    tableRowSideIdSelected = sideId;
                    action = 'remove';
                }

                const postData = {
                    set_id: String(kitId) === this.kitFoundId ? this.kitCustomIdSelected : kitId,
                    action,
                    ids: action == 'catalog-sides-add' ? [tableRowSideIdSelected] : pricesFiltered
                };
                this.postSyncDataCustom(postData, (catalogSideIds) => {
                    const postData = {
                        set_id: this.kitCustomIdSelected,
                        selected: action == 'catalog-sides-add' ? catalogSideIds : [tableRowSideIdSelected]
                    };
                    this.postTableDataRow(postData, (rows, kitItem) => {
                        this.updateKit(kitItem);
                        if(rows.length === 0) {
                            this.afterPostTableDataRow(rows, 'remove', tableRowSideIdSelected);
                        }
                        else {
                            this.afterPostTableDataRow(rows, 'add', '-1');
                        }
                        const {extraData = {}} = this.cardMarker;
                        const {kitId = '-1'} = extraData;
                        let index = this.kitCustoms.findIndex(kit => String(kit.id) === String(kitId == '-2' || String(kitId) === this.kitFoundId ? this.kitCustomIdSelected : kitId));
                        if(kitId == '-2'){
                            this.updateCardMarkersSelected(sideIds, this.$refs.layoutFolder[index].tableRowsCatalogIdsPricesSelected);
                        }else{
                            this.updateCardMarkersSelected(sideIds, this.$refs.layoutFolder[index].tableRowsPricesIdsSelected);
                        }
                    });
                });
            }
        },
        /**
         * Обновить выбранные цены в карточке
         * @param {Array} sideIds - стороны
         * @param {Object} tableRowsPricesIdsSelected - выбранные цены в строке
         */
        updateCardMarkersSelected(sideIds = [], tableRowsPricesIdsSelected = {}) {
            let tableRowPricesIdsSelected = {};
            Object.keys(tableRowsPricesIdsSelected).forEach(rowId => {
                if (sideIds.includes(String(rowId)))
                    tableRowPricesIdsSelected[rowId] = tableRowsPricesIdsSelected[rowId];
            });
            this.cardMarkerSelected = tableRowPricesIdsSelected;
        },
        onRemoveFolder(id) {
            console.log(id);
            if(this.kitCustoms.length > 1) {
                this.onToggleMarkersKit(id, 'custom');
                this.postRemoveKit(id, (id) => {
                    this.afterPostRemoveKit(id);

                    this.onToggleTableKit(this.kitCustoms[0]?.id, 'custom');
                });
            }
            else
                alert('Нельзя удалить последний набор');
        },
        onMoveToBudget(id){
            this.$router.push({path: '/price?setId=' + id});
        },
        /**
         * Дублирование набора
         * @param {String} id
         */
        onDuplicate(id){
            if (!confirm("Вы уверены что хотите дублировать набор?"))
                return;
            this.isLoadingKitsCustom = true;
            this.postDuplicateKit(id, (kit) => {
                this.afterPostCreateKit(kit, false);
                this.isLoadingKitsCustom = false;
            });
        },
        /**
         * Получить от сервера статусы маркеров,
         * изменить формат данных для передачи в LegendPanel
         * @param {Array} statuses - Статусы маркеров
         */
        setMarkersStatuses(statuses) {
            const markersStatuses = [];
            const keysStatuses = Object.keys(statuses);
            const valuesStatuses = Object.values(statuses);
            const findColor = (key) => {
                const markerItem = this.markers.find((el) => el.status === key);
                if (markerItem !== undefined) {
                    return String(markerItem.color_legend);
                }
                return '';
            }

            keysStatuses.forEach((key, index) => {
                markersStatuses.push({
                    key,
                    value: valuesStatuses[index],
                    color: findColor(key),
                });
            });
            const allKeysStatuses = keysStatuses;
            allKeysStatuses.push('without_price');
            if (this.filterSelectedData?.without_prices) {
                markersStatuses.push({
                    color:'#848484',
                    key: 'without_price',
                    value:'Без цены',
                });
            }
            this.markersStatuses = markersStatuses;
            this.setMarkersStatusesListDefault(markersStatuses);
            if (this.allActiveLegendPanelMarkersStatuses.length === 0) {
                this.setAllActiveMarkerStatuses([...allKeysStatuses]);
            }
            this.setActiveMarkerStatuses(allKeysStatuses);
        },
        /**
         * Отобразить маркеры по выбранным статусам в легенде
         */
        onChangeMarkers() {
            if (this.activeLegendPanelMarkersStatuses.length !== 0) {
                // this.clearMarkersFound(this.kitFoundId);

                this.$refs.mapView.updateMarkers((marker) => {
                    const { extraData = {} } = marker;
                    const { kitId: markerKitId = '-1', status = '', type = 'default', sideStatus = {}} = extraData;
                    if (type !== 'poi') {
                        if (String(markerKitId) === '0') {
                            const sidesFilteredKeys = Object.keys(sideStatus).filter(sideKey => this.activeLegendPanelMarkersStatuses.includes(String(sideStatus[sideKey])));
                            marker.sidesFiltered = [...sidesFilteredKeys.map(sideKey => String(sideKey).toUpperCase())];
                            if (marker.sidesFiltered.length > 0) {
                                marker.updateIcon(marker.iconType, false);
                                marker.drawMarker();
                            }
                            else
                                marker.clearMarker();
                        }
                        if (String(markerKitId) === '-2' && this.activeLegendPanelMarkersStatuses.includes(status))
                            marker.drawMarker();
                        else if (String(markerKitId) === '-2' && !this.activeLegendPanelMarkersStatuses.includes(status))
                            marker.clearMarker();
                        if (String(markerKitId) === '-1')
                            marker.clearMarker();
                    }
                });

            } else {
                this.$refs.mapView.updateMarkers((marker) => {
                    const {extraData = {}} = marker;
                    const {kitId: markerKitId = '-1', type = 'default'} = extraData;

                    if (type !== 'poi') {
                        if (String(markerKitId) === '0' || String(markerKitId) === '-2') {
                            marker.clearMarker();
                        }
                    }
                });
            }
        },
        onClickCell(data){
            let foundMarkers = this.$refs.mapView.$refs.linceMapWrapper.serviceMap.markers.filter((marker) => {
                const {id = '-2', extraData = {}} = marker;
                const {kitId = "0"} = extraData;
                return String(id) === data.row.marker_id && kitId === data.kitId;
            });
            if(foundMarkers.length && data.attribute === 'address'){
                this.$refs.mapView.$refs.linceMapWrapper.serviceMap.setMarkerClicked(foundMarkers[0]);
                this.onSelectMarkerRight(foundMarkers[0]);
            }
            if(foundMarkers.length && data.attribute === 'code'){
                this.onFocusMarker(foundMarkers[0]);
            }
        },
        onFocusMarker(marker){
            this.$refs.mapView.$refs.linceMapWrapper.serviceMap.setMarkerClicked(marker, true);
        },
        // onExportFolder(id) {
        //     // this.postExportKit(id, this.afterPostExportKit)
        // },
        /**
         * Экспорт шаблона
         * @returns {Promise<void>}
         */
        async onExportTemplate(isRemoveDoubles){
            if(this.exportingSetId === null){
                alert('Что то пошло не так');
            }
            let bodyPost = {
                set_id: this.exportingSetId,
                is_double_sides: !isRemoveDoubles
            };
            if(this.currentTemplate.id > 0){
                bodyPost.template_id = this.currentTemplate.id;
            }
            this.postExportKitQueue(bodyPost, (history, isTask) => {
                if(isTask){
                    this.$notify.dNotify({
                        type: 'success',
                        title: 'Задача на экспорт успешно добавлена в очередь',
                        description: 'Создание файла может занять некоторое время. Пожалуйста, подождите',
                        duration: Infinity,
                        position: 'bottom-right'
                    });
                }

                const {status = 0, url = null} = history;
                if(Number(status) === 2 && url !== null){
                    this.afterPostExportKit(url);
                }
                this.addHistoryItem(history);
            }, (errorMsg = '') => {
                if (errorMsg) {
                    this.$notify.dNotify({
                        type: 'error',
                        title: 'Не удалось создать задачу на выполнение экспорта',
                        description: errorMsg,
                        duration: Infinity,
                        position: 'bottom-right'
                    });
                }
            });
        },
        /**
         * Получение списка шаблонов
         * @returns {Promise<void>}
         */
        async onGetTemplates(){
            this.postTemplateList((templates) => {
                this.afterPostTemplateList(templates);
            });
        },
        /**
         * Сохранение шаблона
         * @param toSaveTemplate
         * @returns {Promise<void>}
         */
        async onSaveTemplate(toSaveTemplate){
            this.postTemplateSave(toSaveTemplate, (savedTemplate) => {
                this.afterPostTemplateSave(toSaveTemplate, savedTemplate);
            });
        },
        /**
         * Удаление шаблона
         * @returns {Promise<void>}
         */
        async onRemoveTemplate(){
            this.postTemplateRemove(this.currentTemplate.id, () => {
                this.afterPostTemplateRemove();
            });
        },


        /**
         * Нарисовать маркеры пои
         * @param {Array} dataPoi - Список POI данной группы
         */
         onDrawPoiMarkers(dataPoi) {
            this.$refs.mapView.redrawMarkers(() => false, dataPoi, true, false);
        },

        /**
         * Фильтрация точек по радиусу
         *
         * @param {Number} radius
         * @param {String} directionType
         */
        onFilterPoiRadius(radius, directionType){
            this.poiRadiusDirectionType = directionType
            this.poiRadius = Number(radius);
            this.poiPointsDetailed = {};
            if(this.poiRadius > 0){
                this.$refs.mapView.updateMarkers((marker) => {
                    const { extraData = {}, lat = 0, lng = 0 } = marker;
                    const {
                        type = 'default' ,
                        groupId = '',
                        groupName = '',
                        address = '',
                        // description = '',
                        id = ''
                    } = extraData;

                    if (type === 'poi') {
                        const key = id + '-' + groupId;
                        if(typeof this.poiPointsDetailed[key] === 'undefined'){
                            this.poiPointsDetailed[key] = {
                                group_name: groupName,
                                address,
                                // description,
                                lat,
                                lng
                            }
                        }
                    }
                }, false);
            }

            this.onTriggerBounds();
        },

        /**
         * Обновить цвет маркеров
         * @param {String} groupId
         * @param {String} color
         */
        onUpdateColorMarkers(groupId, color){
            this.$refs.mapView.updateMarkers((marker) => {
                const {extraData = {}} = marker;
                const {groupId: markerGroupId = "", type = ""} = extraData;
                if (groupId === markerGroupId && type === "poi") {
                    marker.color = color;
                    marker.fill_color = color;
                    marker.src = "";
                    marker.iconType = "poi";
                    marker.updateIcon(marker.iconType, false);
                }
            }, false);
        },

        /**
         * Обновить иконки маркеров
         * @param {String} groupId
         * @param {String} src
         */
        onUpdateIconMarkers(groupId, src){
            this.$refs.mapView.updateMarkers((marker) => {
                const {extraData = {}} = marker;
                const {groupId: markerGroupId = "", type = ""} = extraData;
                if (groupId === markerGroupId && type === "poi") {
                    marker.src = src;
                    marker.updateIcon(marker.iconType, false);
                }
            }, false);
        },

        /**
         * Удалить маркеры пои
         * @param {String} groupId
         */
        onClearPoiMarkers(groupId){
            this.$refs.mapView.redrawMarkers((marker) => {
                const {extraData = {}} = marker;
                const {groupId: markerGroupId = "", type = ""} = extraData;
                return groupId === markerGroupId && type === "poi";
            }, [], true, false);
        },

        /**
         * Изменить активность маркера
         * @param {Object} marker
         */
        onTogglePoiMarker(marker){
            const {extraData = {}} = marker;
            const {id: poiId = "", groupId = "", isActive = false} = extraData;
            if(!isActive){
                this.$refs.mapView.redrawMarkers((marker) => {
                    const {extraData = {}} = marker;
                    const {groupId: markerGroupId = "", type = "", id = ""} = extraData;
                    return groupId === markerGroupId && id === poiId && type === "poi";
                }, [], true, false);
            }else{
                this.$refs.mapView.redrawMarkers(() => false, [marker], true, false);
            }
        },

        onDrawCompetitorMarkers(groupId, markers){
            let updatedMap = markers.map((marker) => {
                return String(marker.id);
            });
            let updatedMarkers = [...markers];
            this.$refs.mapView.redrawMarkers((marker) => {
                const {extraData = {}, id: markerId = ""} = marker;
                const {groupId: markerGroupId = "", type = ""} = extraData;
                if(groupId === markerGroupId && type === "competitor" && updatedMap.includes(markerId)){
                    updatedMarkers = updatedMarkers.filter(updatedMarker => String(updatedMarker.id) !== String(markerId))
                }
                return groupId === markerGroupId && type === "competitor" && !updatedMap.includes(String(markerId));
            }, [], true, false);
            if(updatedMarkers.length){
                this.$refs.mapView.redrawMarkers(() => false, updatedMarkers, true, false);
            }
        },

        onClearCompetitorMarkers(groupId){
            this.$refs.mapView.redrawMarkers((marker) => {
                const {extraData = {}} = marker;
                const {groupId: markerGroupId = "", type = ""} = extraData;
                return groupId === markerGroupId && type === "competitor";
            }, [], true, false);
        },

        onChangeCompetitorColor(groupId, color){
            this.$refs.mapView.updateMarkers((marker) => {
                const {extraData = {}} = marker;
                const {groupId: markerGroupId = "", type = ""} = extraData;
                if (groupId === markerGroupId && type === "competitor") {
                    Object.keys(marker.sidesColors).forEach((sideName) => {
                        marker.sidesColors[sideName] = marker.correctionColor(color);
                    })
                    marker.updateIcon(marker.iconType, false);
                }
            }, false);
        },

        onCompleteMatching(newKit){
            this.afterPostCreateKit(newKit);
            // this.onToggleMarkersKit(newKit.id, 'custom');
            // this.onToggleTableKit(newKit.id, 'custom');

            // this.isLoadingKitsCustom = true;
            // this.postKitsList((kits) => {
            //     this.afterPostKitsList(kits);
            //     this.$nextTick(() => {
            //         this.onToggleMarkersKit(newKit.id, 'custom');
            //         this.onToggleTableKit(newKit.id, 'custom');
            //     });
            //     this.isLoadingKitsCustom = false;
            // });
        },
        getPoiCoordsByClick(coords) {
            this.moiPoiCoords = coords
        },

        onMapClick(coords) {
            this.getPoiCoordsByClick(coords)
        },

        /**
         * Проверка на смену города
         * @returns {boolean}
         */
        checkMapScale(){
            const {id = null, lat = null, lng = null, zoom = null} = this.currentCity;
            if(id !== null && String(id) !== this.currentCityId){
                this.$refs.mapView.focusMap(lat, lng, zoom);
                this.currentCityId = String(id);
                return true;
            }
            return false;
        },

        /** Обновление маркеров при скролле и перемещении карты */
        onBoundsChanged(bound,zoom, isForce = false){
            if(!this.isNeedUpdateMarkers(bound, zoom) && !isForce){
                console.log("update not need");
                return;
            }

            if(!this.kitsMarkersActive.length && !this.isWithoutPrices){
                return;
            }

            console.log("updating markers");

            const {latMin, latMax, lngMin, lngMax} = bound;
            this.postGetClusterZoom(
                {
                    filter_params: this.filterSelectedData,
                    is_found: this.kitsMarkersActive.includes(this.kitFoundId),
                    bound: [
                        [latMin, lngMin],
                        [latMax, lngMax]
                    ],
                    radius: this.poiRadius,
                    points: this.poiPoints,
                    set_ids: this.kitsMarkersActive.filter(kitId => kitId !== this.kitFoundId),
                },
                (data) => {
                    const {view_zoom: viewZoom = null, is_grouping: isGrouping = true} = data;
                    this.currentViewZoom = viewZoom;

                    this.isWithoutPrices = !!this.filterSelectedData.without_prices
                    if(this.isWithoutPrices){
                        this.redrawWithoutPricesClusters(bound, viewZoom, isGrouping);
                    }else{
                        this.eraseWithoutPrices();
                    }

                    this.kitsMarkersActive.forEach((kitId) => {
                        this.isLoadingMap = true;
                        if(String(kitId) === this.kitFoundId){
                            this.refreshMarkersFound(
                                bound, viewZoom, isGrouping,
                                (loadedBound) => {
                                    this.loadedBound = loadedBound;
                                    this.currentZoom = zoom;
                                    this.updateLegendMarkersColors("found");
                                }
                            );
                        } else {
                            const index = this.kitCustoms.findIndex(kit => String(kit.id) === String(kitId));
                            this.$refs.layoutFolder[index].getMarkersAndClusters(
                                bound, viewZoom, isGrouping, this.poiRadius, this.poiPoints,
                                this.poiRadiusDirectionType === ''
                                    ? null : this.poiRadiusDirectionType,
                                (loadedBound) => {
                                    this.loadedBound = loadedBound;
                                    this.currentZoom = zoom;
                                }
                            );
                        }
                    })
                }
            )


        },

        /** Условие повторной загрузки маркеров */
        isNeedUpdateMarkers(bound, zoom) {
            const {latMin, latMax, lngMin, lngMax} = bound;
            return this.loadedBound !== null
                && (
                    zoom !== this.currentZoom
                    || latMin < this.loadedBound.latMin
                    || latMax > this.loadedBound.latMax
                    || lngMin < this.loadedBound.lngMin
                    || lngMax > this.loadedBound.lngMax
                )
        },

        /**
         * Приближение при клике на кластер
         * @param {Object} marker
         */
        onClickCluster(marker) {
            const {lat, lng} = marker;
            this.$refs.mapView.focusMap(lat, lng, this.currentZoom + 2);
        },

        /**
         * @param {Number} kitId
         * @param {Array} clusters
         */
        addClusters(kitId, clusters) {
            this.removeClusters(kitId, false);

            clusters.forEach((cluster) => {
                const {quadkey} = cluster;

                let bufferCluster = this.clusters[quadkey] ?? null;
                if (bufferCluster === null) {
                    bufferCluster = {...cluster};
                    bufferCluster.extraData = {
                        usedKits: []
                    };
                }

                if (!bufferCluster.extraData.usedKits.includes(String(kitId))) {
                    bufferCluster.extraData.usedKits.push(String(kitId));
                }

                this.clusters = {
                    ...this.clusters,
                    [quadkey]: bufferCluster
                };
            })

            this.$refs.mapView.redrawClusters(Object.values(this.clusters));
        },

        /**
         * Удаление кластеров по набору
         * @param {Number} kitId
         * @param {Boolean} isRender
         */
        removeClusters(kitId, isRender = true) {
            let removeQuadKeys = Object.values(this.clusters).reduce((curRemoveQuadKeys, cluster) => {
                const {quadkey} = cluster;

                let bufferCluster = this.clusters[quadkey] ?? null;
                if (bufferCluster === null) {
                    return curRemoveQuadKeys;
                }

                const usedKits = bufferCluster.extraData.usedKits.filter(currentKitId => String(currentKitId) !== String(kitId));
                bufferCluster.extraData = {
                    ...bufferCluster.extraData,
                    usedKits
                };

                if (!bufferCluster.extraData.usedKits.length) {
                    curRemoveQuadKeys.push(quadkey)
                } else {
                    this.clusters = {
                        ...this.clusters,
                        [quadkey]: bufferCluster
                    };
                }
                return curRemoveQuadKeys;
            }, []);

            removeQuadKeys.forEach((removeQuadKey) => {
                if (removeQuadKey in this.clusters) {
                    delete this.clusters[removeQuadKey]
                }
            })

            if (isRender) {
                this.$refs.mapView.redrawClusters(Object.values(this.clusters));
            }
        },

        /** Кэш отложенных функция */
        onReadyMap() {
            this.runTriggers()
        },

        /**
         * Изменение типа иконок
         * @param {String} currentIconType
         */
        onChangeIconType(currentIconType) {
            this.currentIconType = currentIconType;
        }
    }
}
</script>

<style lang="scss" scoped>
.page-bayuerdesk {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    //padding: 5px;
    &__loading {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 99999;
        background: #fff;
        padding: 0px 40px 40px 40px;

        &-enter-active, &-leave-active {
            transition: opacity 300ms;
        }

        &-enter, &-leave-to {
            opacity: 0;
        }
    }

    &-map {
        flex-grow: 1;
        width: 100%;
        height: 100px;
        //margin-top: 10px;
        position: relative;
        //border-radius: 10px;

        &__progressbar {
            position: absolute;
            z-index: 1;
        }

        &__wrapper {
            overflow: hidden;
            border-radius: inherit;
            height: 100%;
            width: 100%;
        }

        &__construction-card {
            position: absolute;
            right: 10px;
            top: 10px;
            max-height: calc(100% - 20px);
            // max-height: 500px;
            z-index: 40;
            // width: 997px;
        }
    }
}
</style>
