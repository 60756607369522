/**
 * Схема для карты
 * @type {Object}
 * @property {Array<String>} panelItemsVisible - конфиг видимых элементов панели управления
 */
const SchemeMap = {
    panelItemsVisible: {
        google: [
            'selection',
            'massSelection',
            'directionEdit',
            'directionAngleView',
            'directionFlowSide',
            'pedestrianTraffic',
            'autoTraffic',
            'jamsTraffic',
            'colorMap',
            'ruler',
            // 'heatmap'
            // 'clustering'
        ],
        yandex: [
            'selection',
            'massSelection',
            'directionEdit',
            'directionAngleView',
            'directionFlowSide',
            // 'pedestrianTraffic',
            // 'autoTraffic',
            'jamsTraffic',
            'colorMap',
            'ruler',
            // 'heatmap'
            // 'clustering'
        ]
    }
}

export default SchemeMap;