<template>
    <div :class="['base-input', `is-theme--${theme}`]">
        <input
            :type="type"
            :value="value"
            :placeholder="placeholder"
            class="base-input__wrapper"
            @input="onInput"
            @focus="onFocus"
            @blur="onBlur"
            @keyup.enter="onEnter"
        />
        <slot/>
    </div>
</template>

<script>
/** Компонент базового инпута */
export default {
    name: 'BaseInput',
    /**
     * Входные данные компонента
     * @property {String} value - значение инпута
     * @property {String} placeholder - описание инпута
     */
    props: {
        value: {
            type: [String, Number],
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'text'
        },
        mask: {
            type: RegExp,
            default: undefined
        },
        theme: {
            type: String,
            default: 'default',
            validator: value => ['default', 'filter'].includes(value)
        },
    },
    /**
     * Данные компонента
     * @property {Object} inputLazyTimerId - идентификатор таймера ленивого ввода
     */
    data: () => ({
        inputLazyTimerId: null
    }),
    methods: {
        /**
         * Событие ввода
         * @param {Object} event - объект события
         */
        onInput(event) {
            const {target = {}} = event;
            let {value = ''} = target;

            if (this.mask) {
                const formattedValue = value.replace(this.mask, '');
                event.target.value = formattedValue;
                value = formattedValue;
            }
            this.onInputLazy(String(value));
            this.$emit('onInput', String(value));
        },
        /**
         * Событие ленивого ввода
         * @param {String} value - значение инпута
         */
        onInputLazy(value = '') {
            if (this.inputLazyTimerId !== null) {
                clearTimeout(this.inputLazyTimerId);
                this.inputLazyTimerId = null;
            }
            this.inputLazyTimerId = setTimeout(() => {
                this.$emit('onInputLazy', value);
            }, 900);
        },
        onFocus() {
            this.$emit('onFocus');
        },
        onBlur() {
            setTimeout(() => {
                this.$emit('onBlur');
            }, 200);
        },
        onEnter() {
            this.$emit('onEnter');
        }
    }
}
</script>

<style lang="scss" scoped>
.base-input {
    padding: 0 15px 0 15px;
    border-radius: 4px;
    background-color: #F2F2F2;
    display: flex;
    align-items: center;
    height: 100%;
    max-height: 36px;

    &__wrapper {
        height: 100%;
        max-height: 36px;
        min-width: 20px;
        width: 100%;
        // width: 220px;

        // @media screen and (max-width: 1024px) {
        //     width: 80px;
        // }

        border: none;
        background-color: #F2F2F2;

        &:focus {
            outline: none;
        }
    }

    input::placeholder {
        color: #afb0b4;
    }

    &.is-theme {
        &--filter {
            padding: 0;
            max-height: unset;
            border-radius: var(--d-border-radius--2xs);
            overflow: hidden;

            input {
                padding: 4px 12px;
                min-height: 38px;
            }
        }
    }
}
</style>
