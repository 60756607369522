<template>
	<WindowFlexible class="card">
		<template #header>
			<AppHeader
				:selectedSideId="selectedSideId"
				:sides="sides"
				:lat="lat"
				:lng="lng"
				:direction="direction"
                :supplierLink="supplierLink"
				class="card__header"
				@yandex="onYandex"
				@google="onGoogle"
				@close="onClose"
				@changeSide="onChangeSide"
			/>
		</template>

		<template #content>
			<div class="card__body">
				<AppDescription
					:address="address"
					:city="city"
					:view="view"
					:supplier="supplier"
					:photoSmall="photoSmall"
					:photoBig="photoBig"
					:ots="ots"
					:grp="grp"
                    :lastPrice="lastPriceValue"
					:tableData="tableData"
					class="card__description"
				/>
				<AppTable
					v-if="prices.length"
					class="card__table"
					:selectedData="selectedData"
					:prices="prices"
					:filterSelectedDates="filterSelectedDates"
					@onCheckPrice="onCheckPrice"
				/>
				<AppFooter
					class="card__footer"
					:lastUpdate="lastUpdate"
                    :isLegend="!!prices.length"
				/>
			</div>

			<AppPreloader v-if="isLoad" class="card__preloader"/>
		</template>
	</WindowFlexible>
</template>

<script>
import WindowFlexible from "@/components/WindowFlexible"
import AppHeader from "./Components/Header"
import AppDescription from "./Components/Description"
import AppTable from "./Components/Table"
import AppFooter from "./Components/Footer"
import AppPreloader from "./Components/Assets/Loader"
import MixinNumberModify from "@/mixins/MixinNumberModify";

export default {
	name: "ConstructionCard",
	components: {
		WindowFlexible,
		AppHeader,
		AppDescription,
		AppTable,
		AppFooter,
		AppPreloader,
	},
    mixins: [
        MixinNumberModify
    ],
	props: {
        selectedData: {
            type: Object,
            default: () => ({})
        },
        lastPrice: {
            type: String,
            default: null
        },
		isLoad: {
			type: Boolean,
			default: false
		},
		sides: {
			type: Array,
			default: () => []
		},
		prices: {
			type: Array,
			defaut: () => []
		},
        supplierLink: {
            type: String,
            default: ""
        },
        tax: {
            type: String,
            default: ""
        },
		address: {
			type: String,
			default: "-"
		},
		city: {
			type: String,
			default: "-"
		},
		material: {
			type: String,
			default: "-"
		},
		side: {
			type: String,
			default: "-"
		},
		view: {
			type: String,
			default: "-"
		},
		photoSmall: {
			type: String,
			default: "-"
		},
		photoBig: {
			type: String,
			default: "-"
		},
        techReq: {
            type: String,
            default: ""
        },
        isTechReqUrl: {
            type: Boolean,
            default: null
        },
        oohdeskId: {
            type: String,
            default: null
        },
		lastUpdate: {
			type: Object,
			default: () => ({})
		},
		format: {
			type: String,
			default: "-"
		},
		moveDirection: {
			type: String,
			default: "-"
		},
		lat: {},
		lng: {},
		ots: {},
		grp: {},
		light: {},
		gid: {},
		type: {},
		direction: {},
		supplier: {},
		timeStudy: {},
		videoDuration: {},
		videoBlockClients: {},
		number: {},
		selectedSideId: {},
		filterSelectedDates: {
			type: Array,
			default: () => ([])
		}
	},
	computed: {
		tableData() {
			const light = this.light == 1 ? 'Да' : 'Нет';
            let tableData = [
                { key: 'Освещение', value: light },
                { key: 'Материал', value: this.material },
                { key: 'Направление', value: this.moveDirection },
                { key: 'Формат', value: this.format },
                { key: 'Налог', value: this.tax}
            ];
            if(this.isTechReqUrl === true){
                tableData.push({key: 'Тех. требования', link: this.techReq})
            }
            if(this.isTechReqUrl === false){
                tableData.push({key: 'Тех. требования', link: `/tech-requirements/${this.oohdeskId}`})
            }
			return tableData;
		},
        /* eslint-disable no-mixed-spaces-and-tabs */
        lastPriceValue(){
            if(this.lastPrice)
                return this.numberFormat(Number(this.lastPrice), 0, '.', ' ') + " ₽";
            else
                return null;
        }
	},
	methods: {
		onYandex() {
			this.$emit('yandex')
		},
		onGoogle() {
			this.$emit('googlePanorama', this.lat, this.lng)
		},
		onClose() {
			this.$emit('close')
		},
		onChangeSide(id) {
			this.$emit('checkSide', id);
		},
        onCheckPrice(priceId = '-1', sideId = '-1') {
            this.$emit('onCheckPrice', priceId, sideId);
        }
	}
}
</script>

<style lang="scss" scoped>
.card {
	// min-width: 620px;
	// width: 620px;
	background: #fff;
	box-shadow: 0px 3px 29px #3B4A7429;
	border-radius: 15px;
	overflow: auto;

	&__header {
		padding: 14px 30px 14px 30px;
	}

	&__body {
		padding: 0 30px 20px 30px;
		overflow: auto;
		height: 100%;
	}

	&__description {
		margin-top: 15px;
	}

	&__table {
		margin-top: 10px;
	}

	&__footer {
		margin-top: 16px;
	}

	&__preloader {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
	}
}
</style>
