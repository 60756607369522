/** Утилита для работы с датами */
export default class UtilDate {

    static months = {
        'Янв': 'Январь',
        'Фев': 'Февраль',
        'Мар': 'Март',
        'Апр': 'Апрель',
        'Май': 'Май',
        'Июн': 'Июнь',
        'Июл': 'Июль',
        'Авг': 'Август',
        'Сен': 'Сентябрь',
        'Окт': 'Октябрь',
        'Ноя': 'Ноябрь',
        'Дек': 'Декабрь',
    };

    static monthsNames = {
        '01': 'Янв',
        '02': 'Фев',
        '03': 'Мар',
        '04': 'Апр',
        '05': 'Май',
        '06': 'Июн',
        '07': 'Июл',
        '08': 'Авг',
        '09': 'Сен',
        '10': 'Окт',
        '11': 'Ноя',
        '12': 'Дек',
    };

    static monthsIndexes = {
        'Янв': 0,
        'Фев': 1,
        'Мар': 2,
        'Апр': 3,
        'Май': 4,
        'Июн': 5,
        'Июл': 6,
        'Авг': 7,
        'Сен': 8,
        'Окт': 9,
        'Ноя': 10,
        'Дек': 11,
    };

    static getDay(date = '') {
        return String(date).split('-')[2] ?? '';
    }

    static getMonthsName(date = '') {
        const mm = String(date).split('-')[1] ?? '';
        return this.monthsNames[mm];
    }

    static getMonthsNameFull(date = '') {
        return this.months[this.getMonthsName(date)];
    }

    static getMonthIndex(month = '') {
        return this.monthsIndexes[month] ?? -1;
    }

    /**
     * Получить из списка дат ближайщую к текущему системному времени
     * @static
     * @public
     * @param {Array} dates - список дат
     * @param {Boolean} nowIfEmpty - подставлять текущий месяц если ничего не найдено
     * @returns {String|Boolean}
     */
    static getNearDate(dates = [], nowIfEmpty = true) {
        const datesBuff = [...dates];

        const dateNow = new Date();
        const dateNowString = this.toString(dateNow.getFullYear(), dateNow.getMonth() + 1);
        const timestampNow = Date.parse(dateNowString);

        datesBuff.sort((dateFirst, dateSecond) => {
            const timestampDiffFirst = Math.abs(timestampNow - Date.parse(dateFirst));
            const timestampDiffSecond = Math.abs(timestampNow - Date.parse(dateSecond));
            return timestampDiffFirst - timestampDiffSecond;
        });

        if(nowIfEmpty){
            return datesBuff[0] ?? dateNowString;
        }else {
            return datesBuff[0] ?? false;
        }

    }

    /**
     * Преобразовать дату для работы с дейтпикером
     * @static
     * @public
     * @param {String} date - дата
     * @returns {Object}
     */
    static toDatepickerDate(date) {
        const dateObj = new Date(Date.parse(date));
        return {year: dateObj.getFullYear(), month: dateObj.getMonth() + 1};
    }

    /**
     * Преобразовать дату из формата дейтпикера
     * @static
     * @public
     * @param {Object} date - дата
     * @returns {String}
     */
    static fromDatepickerDate(date) {
        return this.toString(date.year, date.month);
    }

    /**
     * Преобразовать год и месяц в строковую дату
     * @static
     * @public
     * @param {Number} year - год
     * @param {Number} month - месяц
     * @returns {String}
     */
    static toString(year, month){
        const monthAdapted = month < 10 ? `0${month}` : String(month);
        return `${year}-${monthAdapted}-01`;
    }

    /**
     * Преобразовать в красивый вид
     * @static
     * @public
     * @param {Number} year - год
     * @param {Number} month - месяц
     * @param {Boolean} isFull
     * @returns {String}
     */
    static toPrettyString(year, month, day = false, isFull = false){
        let monthName = !isFull ? Object.keys(this.months)[month] : Object.values(this.months)[month];
        return day === false ? `${monthName} ${year ? year : ''}` : `${day} ${monthName.toLowerCase()} ${year}`;
    }

    static getLocaleDate(dateString = '') {
        const date = new Date(String(dateString));
        const options = {year: 'numeric', month: 'numeric', day: 'numeric'};
        return String(dateString) === '' || dateString === null ? '' : date.toLocaleDateString('ru-RU', options);
    }

    static getDatePickerObj(dateString = '') {
        const dateArr = String(dateString).split('-');
        const dateObj = {
            month: parseInt(dateArr[1] ?? 0),
            year: parseInt(dateArr[0] ?? 0)
        }

        return dateObj;
    }

    static getMonthNameList(start = 0, end = 12) {
        return Object.keys(this.months).slice(start, end);
    }

    static getMonthNameListFomCurrent() {
        const dateNow = new Date();
        return this.getMonthNameList(dateNow.getMonth(), 12);
    }

}
